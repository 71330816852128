<template>
  <div class="footer">
    <div class="container">
      <div class="row justify-content-center align-items-center g-4">
        <div class="col-12 col-lg-3 col-md-6 mx-auto my-4">
          <div class="box">
            <router-link to="/" class="logo"
              ><img class="my-2" src="images/logo/logo.png" alt=""
            /></router-link>
            <p>Una empresa donde el pragmatismo sobresale.</p>
            <h4 class="my-2">Siganos</h4>
            <div class="social d-flex gap-2">
              <div class="icon"><i class="bi bi-facebook"></i></div>
              <div class="icon"><i class="bi bi-instagram"></i></div>
              <div class="icon"><i class="bi bi-twitter-x"></i></div>
              <div class="icon"><i class="bi bi-youtube"></i></div>
            </div>
          </div>
        </div>
        <div
          class="col-12 col-lg-3 col-md-6 mx-auto d-flex flex-column my-4 gap-3"
        >
          <h4>site map</h4>
          <ul class="d-flex flex-column gap-3">
            <li class="d-flex align-items-center gap-2">
              <i class="bi bi-chevron-right"></i
              ><router-link to="/home">Inicio</router-link>
            </li>
            <li class="d-flex align-items-center gap-2">
              <i class="bi bi-chevron-right"></i
              ><router-link to="/about">Nosotros</router-link>
            </li>
            <li class="d-flex align-items-center gap-2">
              <i class="bi bi-chevron-right"></i
              ><router-link to="/services">Servicios</router-link>
            </li>
            <li class="d-flex align-items-center gap-2">
              <i class="bi bi-chevron-right"></i
              ><router-link to="/contact">Contacto</router-link>
            </li>
          </ul>
        </div>
        <div
          class="col-12 col-lg-3 col-md-6 mx-auto d-flex flex-column my-4 gap-3"
        >
          <h4>Siganos</h4>
          <ul class="d-flex flex-column gap-3">
            <li class="d-flex align-items-center gap-2">
              <i class="bi bi-chevron-right"></i
              ><a href="https://www.facebook.com/sitcsrd/" target="_blank"
                >facebook</a
              >
            </li>
            <li class="d-flex align-items-center gap-2">
              <i class="bi bi-chevron-right"></i
              ><a href="https://www.instagram.com/sitcsrd/" target="_blank"
                >instagram</a
              >
            </li>
            <li class="d-flex align-items-center gap-2">
              <i class="bi bi-chevron-right"></i
              ><a
                href="https://www.linkedin.com/company/sitcsrd/"
                target="_blank"
                >linkedin</a
              >
            </li>
            <li class="d-flex align-items-center gap-2">
              <i class="bi bi-chevron-right"></i
              ><a href="http://www.twitter.com/sitcsrd/" target="_blank">x</a>
            </li>
          </ul>
        </div>
        <div
          class="col-12 col-lg-3 col-md-6 mx-auto d-flex flex-column my-4 gap-3"
        >
          <h4>Nuestros Servicios</h4>
          <ul class="d-flex flex-column gap-3">
            <li class="d-flex align-items-center gap-2">
              <i class="bi bi-chevron-right"></i
              ><router-link to="/service-single"
                >Desarrollo de Software</router-link
              >
            </li>
            <li class="d-flex align-items-center gap-2">
              <i class="bi bi-chevron-right"></i
              ><router-link to="/service-single">Base de Datos</router-link>
            </li>
            <li class="d-flex align-items-center gap-2">
              <i class="bi bi-chevron-right"></i
              ><router-link to="/service-single">Integración APIs</router-link>
            </li>
            <li class="d-flex align-items-center gap-2">
              <i class="bi bi-chevron-right"></i
              ><router-link to="/service-single">Servicio SEO</router-link>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <hr />
    <div class="copyrights">
      <div class="container">
        <div class="row">
          <div class="col-12 col-md-6 d-flex justify-content-start">
            <p class="creadits">
              &copy; 2024 Creado por: <a href="/">www.sitcsrd.com</a>
            </p>
          </div>
          <div class="col-12 col-md-6 d-flex justify-content-end">
            <div>
              <router-link to="/terms-of-use">Condiciones de uso</router-link>
              |
              <router-link to="/privacy-policy"
                >Política de Privacidad</router-link
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup></script>