<template>
  <!-- ======= srart Breadcrumbs ======= -->
  <section class="breadcrumbs py-4" data-aos="fade-up" data-aos-delay="100">
    <div class="container">
      <ol>
        <li><router-link to="/">Inicio</router-link></li>
        <li>Productos</li>
      </ol>
      <h2 class="title">Productos</h2>
      <p class="col-lg-10 col-12">Puede ver algunos de los productos desarrollados por Sitcs.</p>
    </div>
  </section>
  <!-- End Breadcrumbs -->
  <div id="particles-js"></div>
  <!--Portfolio-->
  <portfolio-section></portfolio-section>
  <!--Technologies-->
  <technology-section></technology-section>
  <!-- FAQ Seection -->
  <faq-section></faq-section>
</template>