<template>
  <!-- ============== Start numbers section ========== -->
  <section class="counter-up my-5" data-aos="fade-up" data-aos-delay="100">
    <div class="container">
      <div class="row">
        <div
          class="col-lg-3 col-12 mt-5 mt-lg-0"
          data-aos="fade-right"
          data-aos-delay="200"
        >
          <i class="ri-discuss-line icon"></i>
          <h1 class="counter">+4</h1>
          <h2 class="title-2">Clientes</h2>
        </div>
        <div
          class="col-lg-3 col-12 mt-5 mt-lg-0"
          data-aos="fade-up"
          data-aos-delay="250"
        >
          <i class="ri-discuss-line icon"></i>
          <h1 class="counter">5</h1>
          <h2 class="title-2">Recursos</h2>
        </div>
        <div
          class="col-lg-3 col-12 mt-5 mt-lg-0"
          data-aos="fade-down"
          data-aos-delay="250"
        >
          <i class="ri-discuss-line icon"></i>
          <h1 class="counter">+12</h1>
          <h2 class="title-2">Proyectos Completados</h2>
        </div>
        <div
          class="col-lg-3 col-12 mt-5 mt-lg-0"
          data-aos="fade-left"
          data-aos-delay="200"
        >
          <i class="ri-discuss-line icon"></i>
          <div class="counter">+20</div>
          <div class="title-2">Años de Experiencia</div>
        </div>
      </div>
    </div>
  </section>
  <!-- ============== End numbers section ========== -->
</template>