<template>
  <!-- ======= srart Breadcrumbs ======= -->
  <section class="breadcrumbs py-4" data-aos="fade-up" data-aos-delay="100">
    <div class="container">
      <ol>
        <li><router-link to="/">Inicio</router-link></li>
        <li>Contacto</li>
      </ol>
      <h2 class="title">Contactenos</h2>
      <p class="col-lg-10 col-12">Analicemos juntos sus necesidades.</p>
    </div>
  </section>
  <!-- End Breadcrumbs -->
  <div id="particles-js"></div>
  <!--Contact Section-->
  <contact-section></contact-section>
  <!-- FAQ Seection -->
  <faq-section></faq-section>
</template>
<script setup>
</script>