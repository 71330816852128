<template>
  <div class="single-service">
    <div class="container">
      <div class="row justify-content-between">
        <div class="col-lg-8 col-12" data-aos="fade-right" data-aos-delay="150">
          <img src="images/service/banner-img2.png" alt="service-img" />
          <h2 class="title my-2">Project Goals</h2>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Ea rerum
            beatae dolore quod molestias quos tempora veniam distinctio non,
            porro eum. Veniam corrupti veritatis ab maiores quod. Explicabo
            assumenda voluptate unde labore ratione saepe ab omnis quo sit?
            Atque eos eius hic iusto. Ullam distinctio delectus nobis atque! Ea,
            atque.
          </p>
          <p class="my-2">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Ea rerum
            beatae dolore quod molestias quos tempora veniam distinctio non,
            porro eum. Veniam corrupti veritatis ab maiores quod. Explicabo
            assumenda voluptate unde labore ratione saepe ab omnis quo sit?
            Atque eos eius hic iusto. Ullam distinctio delectus nobis atque! Ea,
            atque.
          </p>
          <img
            src="images/service/business-development-2885464-2409431.webp"
            alt="service-img"
          />
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Ea rerum
            beatae dolore quod molestias quos tempora veniam distinctio non,
            porro eum. Veniam corrupti veritatis ab maiores quod. Explicabo
            assumenda voluptate unde labore ratione saepe ab omnis quo sit?
            Atque eos eius hic iusto. Ullam distinctio delectus nobis atque! Ea,
            atque.
          </p>
          <div class="project-results row g-2 my-4">
            <h2 class="title my-2">Project results</h2>
            <div class="col-lg-6 col-12">
              <img src="images/portfolio/1.jpg" alt="portfolio-img" />
            </div>
            <div class="col-lg-6 col-12">
              <img src="images/portfolio/6.jpg" alt="portfolio-img" />
            </div>
            <div class="col-lg-6 col-12">
              <img src="images/portfolio/3.jpg" alt="portfolio-img" />
            </div>
            <div class="col-lg-6 col-12">
              <img src="images/portfolio/2.jpg" alt="portfolio-img" />
            </div>
          </div>
        </div>
        <div
          class="col-lg-3 col-sm-12 client-side row g-2"
          data-aos="fade-left"
          data-aos-delay="150"
        >
          <div class="col-12">
            <div class="box row align-items-center justify-content-center">
              <div class="icon">
                <i class="bi bi-person-lines-fill"></i>
              </div>
              <div class="text">
                <h2 class="title-2 m-0">client</h2>
                <h3>ahmad erramix</h3>
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="box row align-items-center justify-content-center">
              <div class="icon">
                <i class="bi bi-list-check"></i>
              </div>
              <div class="text">
                <h2 class="title-2 m-0">service</h2>
                <h3>digital marketing</h3>
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="box row align-items-center justify-content-center">
              <div class="icon">
                <i class="bi bi-calendar2-check-fill"></i>
              </div>
              <div class="text">
                <h2 class="title-2 m-0">date</h2>
                <h3>10/04/2023</h3>
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="box row align-items-center justify-content-center">
              <div class="icon">
                <i class="bi bi-stopwatch-fill"></i>
              </div>
              <div class="text">
                <h2 class="title-2 m-0">duration</h2>
                <h3>4 days</h3>
              </div>
            </div>
          </div>
          <h2 class="title-2 mt-4">Siganos</h2>
          <div class="row social">
            <div class="icon"><i class="bi bi-facebook"></i></div>
            <div class="icon mx-2"><i class="bi bi-instagram"></i></div>
            <div class="icon mx-2"><i class="bi bi-twitter-x"></i></div>
            <div class="icon mx-2"><i class="bi bi-youtube"></i></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>