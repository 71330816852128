<template>
  <!-- ============== Start portfolio section ========== -->

  <section class="container portfolio py-5" id="portfolio">
    <div class="heading">
      <h4 class="pretitle" data-aos="fade-up">Plantillas y Utilitarios</h4>
      <h1 class="title col-12" data-aos="fade-up" data-aos-delay="100">
        Descarga tus plantillas y Utilitarios
      </h1>
    </div>
    <div v-if="state.records.length > 0" class="row gy-4 gx-4">
      <div
        v-for="(item, index) in state.records"
        :key="index"
        class="col-lg-4 col-md-6 col-12 mx-auto"
        data-aos="fade-left"
        data-aos-delay="250"
      >
        <div class="box card border-0">
          <h1 class="title-2">
            {{ item.title }}
          </h1>
          <div class="col-12">
            <img
              v-if="item.image"
              :src="item.image"
              alt="blog-img"
              class="img-fluid rounded"
            />
            <svg
              v-else
              class="bd-placeholder-img bd-placeholder-img-lg img-fluid w-100"
              width="100%"
              height="100%"
              xmlns="http://www.w3.org/2000/svg"
              role="img"
              preserveAspectRatio="xMidYMid slice"
              focusable="false"
            >
              <title>Placeholder</title>
              <rect width="100%" height="100%" fill="#868e96"></rect>
              <text x="50%" y="50%" fill="#dee2e6" dy=".3em">
                Imagen No Disponible
              </text>
            </svg>
          </div>
          <div class="card-body d-flex flex-column">
            <p>
              {{ item.content }}
            </p>
            <a class="learn-more mt-auto" :href="item.url" target="_blank">
              Fuente
            </a>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="my-5 box box-service py-4 text-center">
      <h1 class="title box py-4">404</h1>
      <h2 class="subtitle py-4">Contenido no encontrado</h2>
    </div>
  </section>
  <!-- ============== End portfolio section ========== -->
</template>
<script setup>
import { reactive, onBeforeMount } from "vue";

const state = reactive({
  records: [],
});

onBeforeMount(() => {
  state.records = [
    {
      id: 1,
      title: "RQMoney",
      content:
        "Una de las preguntas que más recibo es: ¿Cómo manejan sus finanzas personales? y la respuesta es sencilla, además de organización, nos valemos de herramientas para esos fines, como es RQMoney, que es un poco complicada inicialmente configurarla, pero luego que la tienes configurada es sumamente sencilla.",
      image: "images/utilities/rqmoney.png",
      url: "https://www.rqmoney.eu/",
    },
    {
      id: 2,
      title: "Keep Pass Portable",
      content:
        "Este software nos permite controlar nuestras claves de todos los web sites, aunque puede ser una herramienta redundante, no todas las contraseñas las almaceno en el navegador o los equipos.",
      image: "images/utilities/keeppass.png",
      url: "https://keepass.info/download.html",
    },
    {
      id: 3,
      title: "PortableApps",
      content:
        "Esta herramienta me permite cargar un grupo de applicaciones organizadas dentro de un contexto parecido a un mini sistema operativo. Es super flexible, por ejemplo, RQMoney solo tuve que colocar el folder con el ejecutable y listo.",
      publisher: "Ely Michael Núñez",
      image:
        "images/utilities/portableapps.png",
      url: "https://portableapps.com/",
    },
  ];
  //state.records = [];
});
</script>