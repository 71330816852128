<template>
  <!-- ============== Start Why us section ========== -->
  <section class="container why-choose-us py-5">
    <div class="heading">
      <h4 class="pretitle" data-aos="fade-up">Por qué escogernos?</h4>
      <h1 class="title col-12" data-aos="fade-up" data-aos-delay="100">
        Por qué nuestros clientes nos escogen?
      </h1>
      <p class="col-lg-7 col-12" data-aos="fade-up" data-aos-delay="150">
        Además de nuestro gran conocimiento en desarrollo de sofware seguros,
        integraciones de sistemas y base de datos, contamos con mucha
        experiencia en desarrollo de software para seguros bajo ley HIPAA,
        medición publicitaria, banca internacional, ERP y CRM.
      </p>
    </div>
    <div class="row gy-4 gx-4">
      <div
        class="col-md-6 col-lg-4 mx-auto"
        data-aos="fade-right"
        data-aos-delay="250"
      >
        <div class="box">
          <h1 class="my-4">01.</h1>
          <h2 class="title-2 my-2">últimas tecnologías</h2>
          <p>
            Trabajamos con las últimas tecnologías que se encuentran disponible
            en el mercado.
          </p>
        </div>
      </div>
      <div
        class="col-md-6 col-lg-4 mx-auto"
        data-aos="fade-up"
        data-aos-delay="200"
      >
        <div class="box">
          <h1 class="my-4">02.</h1>
          <h2 class="title-2 my-2">Soluciones en la nube</h2>
          <p>
            Nuestros principales aplicaciones y servicios se encuentran
            instalados en las principales nubes (Amazon AWS, Microsoft Azure,
            Google Cloud, etc)
          </p>
        </div>
      </div>
      <div
        class="col-md-6 col-lg-4 mx-auto"
        data-aos="fade-left"
        data-aos-delay="250"
      >
        <div class="box">
          <h1 class="my-4">03.</h1>
          <h2 class="title-2 my-2">Expertos en Negocios</h2>
          <p>
            Contamos con una basta experiencia en desarrollo de aplicaciones de
            banca, transacciones médicas bajo la ley HIPAA, medición
            publicitaria, etc.
          </p>
        </div>
      </div>
    </div>
  </section>
  <!-- ============== End Why us section ========== -->
</template>