<template>
    <!-- ======= srart Breadcrumbs ======= -->
  <section class="breadcrumbs py-4" data-aos="fade-up" data-aos-delay="100">
    <div class="container">
      <ol>
        <li><router-link to="/">Inicio</router-link></li>
        <li>Plantillas</li>
      </ol>
      <h2 class="title">Plantillas</h2>
      <p class="col-lg-10 col-12">Descarga tus plantillas para que puedas automatizar tus tareas de oficina.</p>
    </div>
  </section>
  <div id="particles-js"></div>
  <!-- Templates Section-->
  <templates-section></templates-section>
  <!-- FAQ Seection -->
  <faq-section></faq-section>
</template>