<template>
  <!-- ============== Start Blog section ========== -->
  <section class="container blog py-5" id="blog">
    <div class="heading">
      <h4 class="pretitle" data-aos="fade-up">blog</h4>
      <h1 class="title col-12" data-aos="fade-up" data-aos-delay="100">
        Nuestro blog
      </h1>
      <p class="col-lg-7 col-12" data-aos="fade-up" data-aos-delay="150">
        Noticias de interés, buenas prácticas del sector e información
        relacionadas al mundo del negocio y la tecnología.
      </p>
    </div>

    <div v-if="state.records.length > 0" class="row gy-4 gx-4">
      <div
        v-for="(item, index) in state.records"
        :key="index"
        class="col-lg-4 col-md-6 col-12 mx-auto"
        data-aos="fade-left"
        data-aos-delay="250"
      >
        <div class="box card border-0">
          <div class="col-12">
            <img :src="item.image" alt="blog-img" />
          </div>
          <div class="card-body">
            <div class="info d-flex justify-content-between">
              <h3>
                <i class="bi bi-person-fill title-2 mx-2"></i
                >{{ item.publisher }}
              </h3>
              <h3>
                <i class="bi bi-calendar-date-fill title-2 mx-2"></i
                >{{ item.date }}
              </h3>
              <h3>
                <i class="bi bi-tag-fill title-2 mx-2"></i>{{ item.type }}
              </h3>
            </div>
            <h1 class="title-2">
              {{ item.title }}
            </h1>
            <p>
              {{ item.content }}
            </p>
            <router-link class="learn-more" to="/blog-single">
              leer más <i class="bi bi-arrow-right"></i>
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="my-5 box box-service py-4 text-center">
      <h1 class="title box py-4">404</h1>
      <h2 class="subtitle py-4">Contenido no encontrado</h2>
    </div>
  </section>
  <!-- ============== End Blog section ========== -->
</template>
<script setup>
import { reactive, onBeforeMount } from "vue";

const state = reactive({
  records: [],
});

onBeforeMount(() => {
  state.records = [
    {
      id: 1,
      title: "how to learn javascript easily with this method",
      content:
        "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Explicabo earum id assumenda ad neque recusandae, quasi deleniti voluptatum eos vel quas molestias?",
      type: "marketing",
      date: "27/05/2023",
      publisher: "web admin",
      image: "images/blog/post-images/1.jpg",
    },
    {
      id: 2,
      title: "why you should learn this new javascript framework",
      content:
        "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Explicabo earum id assumenda ad neque recusandae, quasi deleniti voluptatum eos vel quas molestias?",
      type: "marketing",
      date: "27/03/2023",
      publisher: "web dev",
      image: "images/blog/post-images/2.jpg",
    },
    {
      id: 3,
      title: "What is digital marketing and why is important?",
      content:
        "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Explicabo earum id assumenda ad neque recusandae, quasi deleniti voluptatum eos vel quas molestias?",
      type: "marketing",
      date: "20/04/2023",
      publisher: "admin",
      image: "images/blog/post-images/3.jpg",
    },
  ];
  state.records = [];
});
</script>