import { createApp } from 'vue'
import App from './App.vue'
import router from "./router";

import components from "./components";
//import utils from "./Utils";

import axios from 'axios'
import VueAxios from 'vue-axios'

const app = createApp(App)
    .use(router)
    .use(VueAxios, axios);

components(app);
//utils(app);
app.mount("#app");