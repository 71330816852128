<template>
    <!-- ============== Start clients section ========== -->

  <div class="clients py-lg-5 py-2 my-2" data-aos="fade-up" data-aos-delay="100">
    <div class="container">
      <div class="row">
        <div class="owl-carousel client owl-theme">
          <div class="item">
            <img src="images/clients-logos/new-link.png" alt="New-Link-Logo">
          </div>
          <div class="item">
            <img src="images/clients-logos/Nestle.png" alt="Nestle-logo">
          </div>
          <div class="item">
            <img src="images/clients-logos/uasd-red-cafeteria.png" alt="Uasd-red-cafeteria-logo">
          </div>
          <div class="item">
            <img src="images/clients-logos/guiadealturas.jpg" alt="guiadealturas-logo">
          </div>
          <div class="item">
            <img src="images/clients-logos/mediaexpress.gif" alt="MediaExpress-logo">
          </div>
          <div class="item">
            <img src="images/clients-logos/mediatracker.gif" alt="MediaTracker-logo">
          </div>
          <div class="item">
            <img src="images/clients-logos/negored.gif" alt="Negored-logo">
          </div>
          <div class="item">
            <img src="images/clients-logos/Emevenca.gif" alt="Emevenca-logo">
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- ============== End clients section ========== -->
</template>
<script setup>
import { onMounted } from "vue";
// import { ApiAdminService} from "@/services/apiAdminService";
onMounted(async ()=>{
  // var response = await ApiAdminService.GetTechnologies();
  // if(response.status == 200) {
  //   console.log(response.data);
  // }
});
</script>