<template>
  <!-- ======= srart Breadcrumbs ======= -->
  <section class="breadcrumbs py-4" data-aos="fade-up" data-aos-delay="100">
    <div class="container">
      <ol>
        <li><router-link to="/">Inicio</router-link></li>
        <li>Servicios</li>
      </ol>
      <h2 class="title">Nuestros Servicios</h2>
      <p class="col-lg-10 col-12">
        Aquí tenemos un resumen de los servicios que ofrecemos, si está
        interesado en alguno de nuestros servicios puede contactarnos.
      </p>
    </div>
  </section>
  <!-- End Breadcrumbs -->
  <div id="particles-js"></div>
  <!--Services-->
  <services-section></services-section>
  <!-- FAQ Seection -->
  <faq-section></faq-section>
</template>