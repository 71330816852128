<template>
  <div class="single-service">
    <div class="container">
      <div class="row justify-content-between">
        <div class="col-lg-7 col-12" data-aos="fade-right" data-aos-delay="200">
          <img src="images/service/banner-img2.png" alt="service-img" />
          <h2 class="title my-2">
            Información sobre los servicios que ofrecemos
          </h2>
          <p>
            Ofrecemos servicios de asesorías y consultoría de software y base de
            datos SQL así como integración de aplicaciones de terceros de código
            abiertos existente en el mercado. Como trabajamos:
          </p>
          <ul>
            <li>Escuchamos sus necesidades.</li>
            <li>Le asesoramos sobre el producto o servicio.</li>
            <li>Evaluamos con el cliente los requerimientos.</li>
          </ul>
          <p class="my-2">
            Nos especializamos en el desarrollo de aplicaciones Web y Desktop
            bajo el entorno Windows utilizando las últimas técnicas de
            desarrollo que existen en el mercado. Brindamos servicios de
            analísis y diseño de base de datos. También ofrecemos el servicio de
            modelación de y reestructuración de base de datos.
          </p>
          <ul>
            <li>Sitios Web Corporativos.</li>
            <li>Sitios Web Responsive.</li>
            <li>Aplicaciones Clientes - Servidor.</li>
            <li>Procesamiento masivo datos (Batch y Realtime).</li>
            <li>Web Services y Windows Services.</li>
            <li>Integración con servicios de terceros.</li>
          </ul>
          <img
            src="images/service/business-development-2885464-2409431.webp"
            alt="service-img"
          />
          <h2 class="title my-2">Los que nos hace fuerte</h2>
          <p>
            En Sitcs, la columna vertebral de nuestros servicios se basan en sus
            servicios de backend. Esa experiencia en desarrollo de backend
            garantiza que las aplicaciones funcionen sin problemas, de forma
            segura y sin interrupciones. Aprovechamos las últimas tecnologías y
            las mejores prácticas para crear sistemas backend escalables y
            fáciles de mantener que respalden las operaciones y el crecimiento
            de su negocio.
          </p>
          <div class="project-steps row g-2 my-4">
            <div class="col-lg-6 col-12 mx-auto">
              <div class="box box-service">
                <h3 class="title-2 my-2">
                  <span class="unique-text">1. </span>últimas Tecnologías
                </h3>
                <p>
                  Estamos actualizados con las últimas tecnologías que tenemos
                  en el mercado.
                </p>
              </div>
            </div>
            <div class="col-lg-6 col-12 mx-auto">
              <div class="box box-service">
                <h3 class="title-2 my-2">
                  <span class="unique-text">2. </span>Entregas justo a tiempo
                </h3>
                <p>
                  Le damos mucha importancia a cumplir con los tiempos que
                  prometemos a nuestros clientes.
                </p>
              </div>
            </div>
            <div class="col-lg-6 col-12 mx-auto">
              <div class="box box-service">
                <h3 class="title-2 my-2">
                  <span class="unique-text">3. </span>Estrategia Poderosas
                </h3>
                <p>
                  Cada sistema a la medida necesita una estrategia poderosa que
                  te ayudará a crear un producto distinto a lo que existe en el
                  mercado.
                </p>
              </div>
            </div>
            <div class="col-lg-6 col-12 mx-auto">
              <div class="box box-service">
                <h3 class="title-2 my-2">
                  <span class="unique-text">4. </span>Soluciones únicas
                </h3>
                <p>
                  La ventaja de un desarrollo a la medida es que vas a tener un
                  producto único que te permitirá una ventaja sobre tus
                  competidores.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div
          class="col-lg-4 service-side row g-2"
          data-aos="fade-left"
          data-aos-delay="200"
        >
          <h2 class="title-2">Lista de servicios</h2>
          <div class="col-md-6 col-12 mx-auto service-box">
            <div class="box box-service box-hover">
              <i class="bi bi-code-slash"></i>
              <h3 class="title-2 my-2">Desarrollo de Software</h3>
              <p>
                Nos especializamos en el desarrollo de aplicaciones Web y
                Desktop bajo el entorno Windows.
              </p>
            </div>
          </div>
          <div class="col-md-6 col-12 mx-auto service-box">
            <div class="box box-service box-hover">
              <i class="bi bi-archive"></i>
              <h3 class="title-2 my-2">Base de Datos</h3>
              <p>
                Brindamos servicios de analísis. diseño y recupración de base de
                datos SQL Server y MySQL.
              </p>
            </div>
          </div>
          <div class="col-md-6 col-12 mx-auto service-box">
            <div class="box box-service box-hover">
              <i class="bi bi-search"></i>
              <h3 class="title-2 my-2">Servicio SEO</h3>
              <p>
                Ayudamos a posicionar sus páginas en los principales motores de
                búsquedas como son Google y Bing.
              </p>
            </div>
          </div>
          <div class="col-md-6 col-12 mx-auto service-box">
            <div class="box box-service box-hover">
              <i class="bi bi-code-square"></i>
              <h3 class="title-2 my-2">Integraciones APIs</h3>
              <p>
                Hoy existen muchos servicios que ofrecen soluciones a las
                empresas que funcionan como islas.
              </p>
            </div>
          </div>
          <div class="col-md-6 col-12 mx-auto service-box">
            <div class="box box-service box-hover">
              <i class="bi bi-bug-fill"></i>
              <h3 class="title-2 my-2">Aplicaciones Seguras</h3>
              <p>
                Nos preocupamos porque cada aplicación que desarrollamos cuente
                con las mejores práctica de seguridad.
              </p>
            </div>
          </div>
          <div class="col-md-6 col-12 mx-auto service-box">
            <div class="box box-service box-hover">
              <i class="bi bi-android2"></i>
              <h3 class="title-2 my-2">Desarrollo Apps</h3>
              <p>
                Desarrollo aplicaciones híbridas para ejecutarla desde equipos
                móviles con una gran experiencia de usuarios.
              </p>
            </div>
          </div>
          <h2 class="title-2 mt-4">Siganos</h2>
          <div class="row social">
            <div class="icon"><i class="bi bi-facebook"></i></div>
            <div class="icon mx-2"><i class="bi bi-instagram"></i></div>
            <div class="icon mx-2"><i class="bi bi-twitter-x"></i></div>
            <div class="icon mx-2"><i class="bi bi-youtube"></i></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>