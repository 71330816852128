<template>
  <section
    class="privacy mt-4 pt-4 mb-4 pb-2"
    data-aos="fade-up"
    data-aos-delay="200"
  >
    <div class="container">
      <h1 class="title my-4">Condiciones de uso</h1>
      <p class="my-2">
        última actualización: <span class="unique-text">30/08/2024</span>
      </p>
      <p>Bienvenido a Sitcs!</p>
      <p>
        Al acceder y usar nuestro sitio web y servicios, acepta cumplir y estar
        sujeto a los siguientes términos y condiciones. Si no está de acuerdo
        con alguna parte de estos términos, no utilice nuestro sitio web ni
        nuestros servicios.
      </p>
      <br />
      <h2 class="title-2">1. Aceptación de los términos</h2>
      <p>
        Al utilizar el sitio web y los servicios de Sitcs, usted reconoce que ha
        leído, comprendido y acepta estar sujeto a estos Términos de uso. Si no
        está de acuerdo con alguna parte de estos términos, no podrá utilizar
        nuestro sitio web ni nuestros servicios.
      </p>
      <br />
      <h2 class="title-2">2. Uso de servicios</h2>
      <p>
        Usted acepta utilizar los servicios de Sitcs únicamente para fines
        lícitos y de conformidad con estos Términos de uso. Usted es responsable
        de garantizar que su uso de nuestros servicios no viole ninguna ley o
        normativa aplicable.
      </p>
      <br />
      <h2 class="title-2">3. Acceso restrigido</h2>
      <p>
        Para acceder a ciertas funciones de nuestros servicios, es posible que
        se le solicite crear una cuenta de usuario. Usted es responsable de
        mantener la confidencialidad de la información de su cuenta, incluido su
        nombre de usuario y contraseña. Usted acepta notificarnos de inmediato
        sobre cualquier uso no autorizado de su cuenta.
      </p>
      <br />
      <h2 class="title-2">4. Propiedad Intelectual</h2>
      <p>
        Todo el contenido y los materiales de nuestro sitio web, incluidos,
        entre otros, textos, gráficos, logotipos, imágenes y software, son
        propiedad de [Nombre de su empresa] y están protegidos por las leyes de
        propiedad intelectual. No puede modificar, reproducir, distribuir ni
        mostrar ninguna parte de nuestro sitio web o servicios sin nuestro
        consentimiento previo por escrito.
      </p>
      <br />
      <h2 class="title-2">5. Descargo de responsabilidad de garantías</h2>
      <p>
        Nuestro sitio web y nuestros servicios se proporcionan "tal como están"
        y "según disponibilidad". No ofrecemos garantías, expresas o implícitas,
        con respecto a la precisión, confiabilidad o disponibilidad de nuestro
        sitio web o nuestros servicios. Nos reservamos el derecho de modificar,
        suspender o discontinuar cualquier parte de nuestros servicios sin
        previo aviso.
      </p>
      <br />
      <h2 class="title-2">6. Limitación de responsabilidad</h2>
      <p>
        En ningún caso [Nombre de su empresa] será responsable de ningún daño
        indirecto, incidental, especial, consecuente o punitivo, ni de ninguna
        pérdida de ganancias o ingresos, ya sea incurrida directa o
        indirectamente, que surja de su uso de nuestro sitio web o nuestros
        servicios.
      </p>
      <br />
      <h2 class="title-2">7. Ley aplicable</h2>
      <p>
        Estos Términos de uso se regirán e interpretarán de conformidad con las
        leyes de República Dominicana. Cualquier acción o procedimiento legal
        que surja de o esté relacionado con estos términos se presentará
        exclusivamente en los tribunales ubicados dentro de República
        Dominicana, y usted acepta la jurisdicción de dichos tribunales.
      </p>
      <br />
      <h2 class="title-2">8. Cambios en los términos</h2>
      <p>
        Sitcs se reserva el derecho de actualizar o modificar estos Términos de
        uso en cualquier momento sin previo aviso. Su uso continuo de nuestro
        sitio web y nuestros servicios después de cualquier cambio constituye la
        aceptación de dichos cambios.
      </p>
      <br />
      <br />
      <h2 class="title-2">Contactenos</h2>
      <p>
        Si tienes cualquier duda o pregunta sobre nuestras condiciones e uso,
        favor contactenos al siguiente correo info@sitcsrd.com
      </p>
      <br />
      <p>
        Gracias por elegir Sitcs como su proveedor de servicios. Apreciamos su
        cooperación y adherencia a nuestras condiciones.
      </p>
    </div>
  </section>
</template>