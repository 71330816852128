<template>
  <!-- ============== Start portfolio section ========== -->

  <section class="container portfolio py-5" id="portfolio">
    <div class="heading">
      <h4 class="pretitle" data-aos="fade-up">Producto</h4>
      <h1 class="title col-12" data-aos="fade-up" data-aos-delay="100">
        Nuestros Productos
      </h1>
    </div>
    <ul class="filters d-flex my-4" data-aos="fade-up" data-aos-delay="150">
      <li class="nav-item portfolio-btn active">
        <a class="nav-link" data-filter="*">Todos</a>
      </li>
      <li class="nav-item portfolio-btn mx-lg-3 mx-1">
        <a class="nav-link" data-filter=".mobile">Web</a>
      </li>
      <li class="nav-item portfolio-btn mx-lg-3 mx-1">
        <a class="nav-link" data-filter=".data">Data</a>
      </li>
      <li class="nav-item portfolio-btn mx-lg-3 mx-1">
        <a class="nav-link" data-filter=".web">Apis</a>
      </li>
    </ul>
    <div
      v-if="state.records.length > 0"
      class="row portfolio-row gy-4 gx-4"
      data-aos="fade-up"
      data-aos-delay="150"
    >
      <div
        v-for="(item, index) in state.records"
        :key="index"
        class="col-md-6 col-lg-4 mx-auto mobile"
      >
        <a
          :href="item.image"
          class="gallery-item"
          data-fancybox="gallery"
          :data-caption="item.caption"
        >
          <div class="portfolio-item">
            <img :src="item.image" class="gallery-item" alt="portfolio-img" />
            <div class="img-overlay">
              <div class="content">
                <h3>{{ item.name }}</h3>
                <h6>{{ item.type }}</h6>
                <div class="d-flex tags my-2">
                  <span v-for="lang in item.languages" :key="lang">{{
                    lang
                  }}</span>
                </div>
                <h4>
                  <a class="unique-text" href="/portfolio-single"
                    ><i class="bi bi-eye-fill mx-2"></i>Ver proyecto</a
                  >
                </h4>
              </div>
            </div>
          </div>
        </a>
      </div>
    </div>
    <div v-else class="my-5 box box-service py-4 text-center">
      <h1 class="title box py-4">404</h1>
      <h2 class="subtitle py-4">Contenido no encontrado</h2>
    </div>
  </section>
  <!-- ============== End portfolio section ========== -->
</template>
<script setup>
import { reactive, onBeforeMount } from "vue";

const state = reactive({
  records: [],
});

onBeforeMount(() => {
  state.records = [
    {
      id: 3,
      name: "Ofisalud",
      type: "Web Application",
      image: "images/portfolio/Ofisalud.png",
      languages: ["#AngularJs", "#Net", "#SQL Server", "#Bootstrap"],
      caption: "Web",
    },
    {
      id: 1,
      name: "MediaWatch",
      type: "Web Application",
      image: "images/portfolio/MediaWatch.png",
      languages: ["#Vue3", "#NetCore", "#aws", "#Tailwind", "#MySQL"],
      caption: "Web",
    },
    {
      id: 5,
      name: "Ofidental",
      type: "Web Application",
      image: "images/portfolio/Ofidental.png",
      languages: ["#vuejs", "#Net", "#SQL Server", "#Bootstrap"],
      caption: "Web",
    },
    {
      id: 4,
      name: "Health Plan Data Exchange",
      type: "Web Application",
      image: "images/portfolio/Agilerta.png",
      languages: ["#AngularJs", "#Net", "#SQL Server", "#Bootstrap"],
      caption: "Web",
    },
    {
      id: 6,
      name: "Regalos en Madera",
      type: "web application",
      image: "images/portfolio/Rem.png",
      languages: ["#AngularJs", "#Net", "#SQL Server", "#Bootstrap"],
      caption: "Web",
    },
    {
      id: 2,
      name: "Affair Watch",
      type: "Web Application",
      image: "images/portfolio/AffairWatch.png",
      languages: ["#Vue 3", "#Net Core", "#aws", "#Tailwind"],
      caption: "Web",
    },
  ];
});
</script>
