<template>
  <!-- start preLoader -->
  <div id="preloader">
    <svg id="mainSVG" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 800 600">
      <defs>
        <radialGradient
          id="mainGrad"
          cx="400"
          cy="300"
          fx="400"
          fy="300"
          r="100"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset=".68" stop-color="#09c" />
          <stop offset=".72" stop-color="#0099cc" />
          <stop offset=".77" stop-color="#00a5d9" />
          <stop offset=".82" stop-color="#00b2e6" />
          <stop offset=".88" stop-color="#00bff2" />
          <stop offset=".93" stop-color="#00ccff" />
          <stop offset=".99" stop-color="#33cccc" />
          <stop offset="1" stop-color="#00ccff" />
        </radialGradient>
      </defs>
      <circle id="fillDot" cx="400" cy="300" fill="#09c" r="100" />
      <circle id="gradDot" cx="400" cy="300" fill="url(#mainGrad)" r="100" />
    </svg>
  </div>
  <!-- end preLoader -->
</template>
<script setup>
import { onMounted } from "vue";

onMounted(() => {
  var loader = document.getElementById("preloader");
  setTimeout(() => {
    if (loader) loader.style.display = "none";
  }, 1200);
});
</script>