<template>
  <!-- ============== Start FAQ section ========== -->
  <section class="container faq py-5">
    <div class="heading">
      <h4 class="pretitle" data-aos="fade-up">FAQ</h4>
      <h1 class="title col-12" data-aos="fade-up" data-aos-delay="100">
        Preguntas frecuentes
      </h1>
      <p class="col-lg-7 col-12" data-aos="fade-up" data-aos-delay="150">
        Algunas respuestas a las dudas que presentan algunos prospectos cuando
        se acercan a nosotros.
      </p>
    </div>
    <div class="row justify-content-center align-items-center gx-4">
      <div
        class="col-12 col-lg-6"
        data-aos="fade-right"
        data-aos-delay="200"
        data-tilt
      >
        <img src="images/faq/customer.png" alt="faq" />
      </div>
      <div class="col-12 col-lg-6">
        <div class="col-12 my-4" data-aos="fade-up" data-aos-delay="250">
          <div class="box">
            <div class="d-flex w-100 justify-content-between">
              <a
                data-bs-toggle="collapse"
                class="w-100"
                onclick="rotateIcon('icon1')"
                href="#answer1"
                role="button"
                aria-expanded="false"
              >
                <h4
                  class="d-flex justify-content-between w-100 heading-3 m-0 p-0"
                >
                  ¿Ofrecen soporte remote y en oficinas de servicios IT?
                  <i
                    class="bi bi-chevron-compact-down mx-4 rotate-icon"
                    id="icon1"
                  ></i>
                </h4>
              </a>
            </div>
            <p id="answer1" class="collapse">
              Trabajamos remoto 100%, eso no evita visitas a nuestros cliente
              para alguna reunión presencial, entrenamiento o kick-off de algún
              proyecto si es necesario.
            </p>
          </div>
        </div>
        <div class="col-12 my-4" data-aos="fade-up" data-aos-delay="300">
          <div class="box">
            <div class="d-flex w-100 justify-content-between">
              <a
                data-bs-toggle="collapse"
                class="w-100"
                onclick="rotateIcon('icon2')"
                href="#answer2"
                role="button"
                aria-expanded="false"
              >
                <h4
                  class="d-flex justify-content-between w-100 heading-3 m-0 p-0"
                >
                  ¿Cuáles son las principales tecnología que utilizan?
                  <i
                    class="bi bi-chevron-compact-down mx-4 rotate-icon"
                    id="icon2"
                  ></i>
                </h4>
              </a>
            </div>
            <p id="answer2" class="collapse">
              Trabajamos principalmente desarrollo Web y backend empresariales
              utilizando frameworks conocidos como son Angular, VueJs, Net Core
              y base de datos MSSQL Server y MySQL principalmente.
            </p>
          </div>
        </div>
        <div class="col-12 my-4" data-aos="fade-up" data-aos-delay="350">
          <div class="box">
            <div class="d-flex w-100 justify-content-between">
              <a
                data-bs-toggle="collapse"
                class="w-100"
                onclick="rotateIcon('icon3')"
                href="#answer3"
                role="button"
                aria-expanded="false"
              >
                <h4
                  class="d-flex justify-content-between w-100 heading-3 m-0 p-0"
                >
                  ¿Cuál es su metología de trabajo?
                  <i
                    class="bi bi-chevron-compact-down mx-4 rotate-icon"
                    id="icon3"
                  ></i>
                </h4>
              </a>
            </div>
            <p id="answer3" class="collapse">
              Depende bastante del requerimiento del cliente, para proyectos nos
              basamos mucho en la tecnología PMI, en el caso de las consultorías
              de base de datos e integraciones trabajamos más con tecnología
              Agile. Todas las solicitudes de trabajo son respondidas con una
              propuesta que incluye tiempo de entrega, costo del proyecto y la
              metodología de trabajo por escrito.
            </p>
          </div>
        </div>
        <div class="col-12 my-4" data-aos="fade-up" data-aos-delay="400">
          <div class="box">
            <div class="d-flex w-100 justify-content-between">
              <a
                data-bs-toggle="collapse"
                class="w-100"
                onclick="rotateIcon('icon4')"
                href="#answer4"
                role="button"
                aria-expanded="false"
              >
                <h4
                  class="d-flex justify-content-between w-100 heading-3 m-0 p-0"
                >
                  ¿Trabajan de manera independiente?
                  <i
                    class="bi bi-chevron-compact-down mx-4 rotate-icon"
                    id="icon4"
                  ></i>
                </h4>
              </a>
            </div>
            <p id="answer4" class="collapse">
              Por la experiencia ganada en varios tipos de proyectos de
              tecnología, tenemos la facilidad de entender rápidamente la
              necesidad de nuestros clientes y las posibles soluciones que le
              vamos a brindar, por lo que nos enfocamos mucho en el resultado
              brindandole a nuestros clientes el espacio necesario para
              continuar con sus operaciones diarias.
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- ============== End FAQ section ========== -->
</template>