<template>
    <header class="header">
      <nav class="navbar navbar-expand-lg navbar-light fixed-top">
        <div class="container">
          <router-link class="navbar-brand" to="/"
            ><img src="images/logo/logo.png" class="logo" alt="LOGO"
          /></router-link>
          <button
            class="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <i class="bi bi-list" id="menu"></i>
          </button>
          <div
            class="collapse navbar-collapse justify-content-between"
            id="navbarNav"
          >
            <ul class="navbar-nav ms-auto" id="navbar">
              <li class="nav-item">
                <router-link class="nav-link" to="/home">Inicio</router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" to="/about">Nosotros</router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" to="/services">Servicios</router-link>
              </li>
              <li class="nav-item dropdown">
                <a
                  class="nav-link dropdown-toggle"
                  href="#"
                  id="navbarDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Soluciones
                </a>
                <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                  <li>
                    <router-link class="dropdown-item py-2" to="/components"
                      >Componentes</router-link
                    >
                  </li>
                  <li>
                    <router-link class="dropdown-item py-2" to="/templates"
                      >Plantillas</router-link
                    >
                  </li>
                </ul>
              </li>
              <li class="nav-item">                
                <router-link class="nav-link" to="/portfolio">Productos</router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" to="/blog">Blog</router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" to="/contact">Contactenos</router-link>
              </li>
            </ul>
            <div class="d-flex ms-auto">
              <router-link class="btn" to="/contact">Escribenos</router-link>
              <button id="mode-toggle" class="btn-light-mode switch-button">
                <i id="mode-icon" class="bi bi-moon-fill"></i>
              </button>
            </div>
          </div>
        </div>
      </nav>
    </header>
</template>