<template>
  <!-- ============== Start technologies section ========== -->
  <div
    class="clients py-lg-5 py-2 my-2"
    data-aos="fade-up"
    data-aos-delay="100"
  >
    <div class="container">      
      <div class="row">
        <div class="owl-carousel client owl-theme">
          <div class="item">
            <img src="images/experiences/angularjs.png" alt="Angular-Logo">
          </div>
          <div class="item">
            <img src="images/experiences/Asp_net-1.png" alt="Asp-Net-logo">
          </div>
          <div class="item">
            <img src="images/experiences/bootstrap.png" alt="Bootstrap-logo">
          </div>
          <div class="item">
            <img src="images/experiences/CSS-1.png" alt="CSS-logo">
          </div>
          <div class="item">
            <img src="images/experiences/HTML-1.png" alt="HTML-logo">
          </div>
          <div class="item">
            <img src="images/experiences/jquery.png" alt="JQuery-logo">
          </div>
          <div class="item">
            <img src="images/experiences/microsoft_azure.png" alt="Microsoft-Azure-logo">
          </div>
          <div class="item">
            <img src="images/experiences/Microsoft_net-1.png" alt="Microsoft-Net-logo">
          </div>
          <div class="item">
            <img src="images/experiences/my-sql.png" alt="My-SQL-logo">
          </div>
          <div class="item">
            <img src="images/experiences/SQL_server-1.png" alt="SQL-Server-logo">
          </div>
          <div class="item">
            <img src="images/experiences/Visual_Studio-1.png" alt="Visual-Studio-logo">
          </div>
          <div class="item">
            <img src="images/experiences/vuejs.png" alt="VueJs-logo">
          </div>
        </div>
        <!-- <div
          class="owl-carousel client owl-theme"
          v-if="state.records.length > 0"
        >
          <div class="item" v-for="(item, index) in state.records" :key="index">
            <img :src="item.url" :alt="item.name" />
          </div>
        </div> -->
      </div>
    </div>
  </div>
  <!-- ============== End technologies section ========== -->
</template>
<script setup>
import { onMounted, reactive } from "vue";
import { ApiAdminService } from "@/services/apiAdminService";
import { GetErrorMessage } from "@/helpers/helper";
const state = reactive({
  records: [],
});

const GetImage = (relativeUrl) => {
  return ApiAdminService.GetContent(relativeUrl);
};

onMounted(async () => {
  try {
    var response = await ApiAdminService.GetTechnologies();
    if (response.status == 200) {
      state.records = response.data.data.map((x) => {
        return {
          name: x.attributes.name,
          url: GetImage(x.attributes.cover.data.attributes.url),
        };
      });
    }
  } catch (error) {
    console.log(GetErrorMessage(error));
  }
});
</script>