<template>
  <div>
    <pre-loader></pre-loader>

    <!-- start scroll to top button -->
    <div id="progress">
      <span id="progress-value"><i class="bi bi-arrow-up-short"></i></span>
    </div>
    <!-- end scroll to top button -->

    <!-- ======= start Header ======= -->
    <header-section></header-section>
    <!-- ======= end Header ======= -->
    <router-view />
    <!-- ============== Start Footer section ========== -->
    <footer-section></footer-section>
    <!-- ============== end Footer section ========== -->
  </div>
</template>
<script setup>
</script>