<template>
  <!-- ======= srart Breadcrumbs ======= -->
  <section class="breadcrumbs py-4" data-aos="fade-up" data-aos-delay="100">
    <div class="container">
      <ol>
        <li><router-link to="/">Inicio</router-link></li>
        <li>Política de Privacidad</li>
      </ol>
      <h2 class="title">Política de Privacidad</h2>
      <p class="col-lg-10 col-12">
        Mucho del contenido en esta página son requerimientos de otros clientes, por lo que su contenido no está disponible para el público.
      </p>
    </div>
  </section>
  <!-- End Breadcrumbs -->
  <div id="particles-js"></div>
  <!--Privacy Policy-->
  <privacy-policy-section></privacy-policy-section>
</template>