<template>
  <section
    class="privacy mt-5 pt-5 mb-4 pb-4"
    data-aos="fade-up"
    data-aos-delay="200"
  >
    <div class="container">
      <h1 class="title my-4">Política de privacidad</h1>
      <p class="my-2">
        última actualización: <span class="unique-text">30/08/2024</span>
      </p>
      <p>Bienvenida a Sitcs</p>
      <p>
        En Sitcs, nos comprometemos a proteger la privacidad y la seguridad de
        su información personal. Esta Política de privacidad describe cómo
        recopilamos, usamos, divulgamos y protegemos su información cuando
        utiliza nuestro sitio web y nuestros servicios. Al utilizar nuestro
        sitio web y nuestros servicios, usted acepta las prácticas descritas en
        esta Política de privacidad. Tómese un momento para revisar este
        documento detenidamente.
      </p>
      <br />
      <h2 class="sub-title">Información que recopilamos</h2>
      <p><b class="unique-text my-0">Información Personal</b></p>
      <p class="my-0">
        Podemos recopilar información personal que usted nos proporciona
        directamente, como su nombre, dirección de correo electrónico, número de
        teléfono e información de la empresa. Esta información se recopila
        cuando usted se registra en nuestros servicios, se suscribe a boletines
        informativos o se comunica con nosotros para recibir asistencia.
      </p>
      <br />
      <p><b class="unique-text my-0">Log Data</b></p>
      <p class="my-0">
        Al igual que muchos sitios web, podemos recopilar información que su
        navegador envía cada vez que visita nuestro sitio web. Esto puede
        incluir su dirección IP, tipo de navegador, versión del navegador, las
        páginas de nuestro sitio web que visita, la hora y fecha de su visita,
        el tiempo que pasa en esas páginas y otras estadísticas.
      </p>
      <br />
      <p><b class="unique-text my-0">Cookies</b></p>
      <p class="my-0">
        Utilizamos cookies para recopilar información y mejorar nuestros
        servicios. Las cookies son pequeños archivos de datos que se almacenan
        en su dispositivo. Puede indicarle a su navegador que rechace todas las
        cookies o que le indique cuándo se envía una cookie. Sin embargo, si no
        acepta las cookies, es posible que no pueda utilizar algunas partes de
        nuestro sitio web.
      </p>
      <br />
      <br />
      <h2 class="sub-title">Cómo utilizamos su información</h2>
      <p>Utilizamos la información que recopilamos para diversos fines, entre ellos:</p>
      <ul>
        <li>- Proporcionar, mantener y mejorar nuestros servicios</li>
        <li>
          - Enviarle actualizaciones, boletines informativos y comunicaciones de marketing.
        </li>
        <li>- Responder a sus consultas y brindar asistencia al cliente</li>
        <li>- Analizar datos de uso para mejorar la experiencia del usuario</li>
      </ul>
      <br />
      <br />
      <h2 class="sub-title">Intercambio de información</h2>
      <p>
        No vendemos, comercializamos ni alquilamos su información personal a
        terceros. Sin embargo, podemos compartir su información con terceros de
        confianza que nos ayudan a operar nuestro sitio web, llevar adelante
        nuestro negocio o brindarle servicios, siempre que dichas partes
        acuerden mantener esta información confidencial.
      </p>
      <br />
      <br />
      <h2 class="sub-title">Seguridad</h2>
      <p>
        Nos tomamos muy en serio la seguridad de su información e implementamos
        medidas estándar de la industria para protegerla. Sin embargo, ningún
        método de transmisión a través de Internet o almacenamiento electrónico
        es 100% seguro. Por lo tanto, no podemos garantizar su seguridad
        absoluta.
      </p>
      <br />
      <br />
      <h2 class="sub-title">Tus opciones</h2>
      <p>
        Puede controlar la información que recopilamos ajustando la
        configuración de su navegador, cancelando su suscripción a los boletines
        informativos o comunicándose con nosotros directamente. También puede
        solicitar acceso, corrección o eliminación de su información personal
        comunicándose con nuestro equipo de privacidad.
      </p>
      <br />
      <br />
      <h2 class="sub-title">Cambios a esta Política de Privacidad</h2>
      <p>
        Es posible que actualicemos esta Política de privacidad de vez en
        cuando. La fecha de la última revisión se indicará en la parte superior
        de la página. Le recomendamos que revise esta Política de privacidad
        periódicamente para conocer los cambios.
      </p>
      <br />
      <br />
      <h2 class="sub-title">Contactenos</h2>
      <p>
        Si tienes cualquier duda o pregunta sobre nuestras condiciones e uso,
        favor contactenos al siguiente correo info@sitcsrd.com
      </p>
      <br />
      <p>
        Gracias por elegir Sitcs como su proveedor de servicios. Apreciamos su
        cooperación y adherencia a nuestras condiciones.
      </p>
    </div>
  </section>
</template>