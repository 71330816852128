<template>
  <!-- ============== Start services section ========== -->
  <section class="container services py-5" id="services">
    <div class="heading">
      <h4 class="pretitle" data-aos="fade-up">Nuestros servicios</h4>
      <h1
        class="title col-lg-10 col-12"
        data-aos="fade-up"
        data-aos-delay="100"
      >
        ¿Qué servicios ofrecemos?
      </h1>
      <p class="col-lg-7 col-12" data-aos="fade-up" data-aos-delay="150">
        Estamos especializado en el desarrollo, consultoría y asesoría en
        materia de software y base de datos.
      </p>
    </div>
    <div class="row gy-4 gx-4">
      <!-- service number 1 -->
      <div class="col-md-6 col-12 col-lg-4 mx-auto">
        <div
          class="box box-service box-hover"
          data-aos="fade-right"
          data-aos-delay="250"
        >
          <div class="box-icon my-2">
            <i class="bi bi-code-slash"></i>
          </div>
          <h2 class="title-2 my-2">Desarrollo de Software</h2>
          <p>
            Nos especializamos en el desarrollo de aplicaciones Web y Desktop
            bajo el entorno Windows. Nos dedicamos a crear soluciones escalables
            y eficientes para impulsar los productos y servicios de nuestros
            clientes.
          </p>
          <router-link to="/service-single" class="my-2 learn-more"
            >Saber más <i class="bi bi-arrow-right"></i
          ></router-link>
        </div>
      </div>
      <div
        class="col-md-6 col-lg-4 mx-auto"
        data-aos="fade-down"
        data-aos-delay="200"
      >
        <!-- service number 2 -->
        <div class="box box-service box-hover">
          <div class="box-icon my-2">
            <i class="bi bi-archive"></i>
          </div>
          <h2 class="title-2 my-2">Base de Datos</h2>
          <p>
            Brindamos servicios de analísis. diseño y recupración de base de
            datos SQL Server y MySQL. Contactmos con gran experiencia en el
            diseño de base de datos relacionales y no relaciones.
          </p>
          <router-link to="/service-single" class="my-2 learn-more"
            >Saber más <i class="bi bi-arrow-right"></i
          ></router-link>
        </div>
      </div>
      <div
        class="col-md-6 col-lg-4 mx-auto"
        data-aos="fade-left"
        data-aos-delay="250"
      >
        <!-- service number 3 -->
        <div class="box box-service box-hover">
          <div class="box-icon my-2">
            <i class="bi bi-search"></i>
          </div>
          <h2 class="title-2 my-2">Servicio SEO</h2>
          <p>
            Ayudamos a colocar sus páginas en mejores posiciones en los
            principales motores de búsquedas como son Google y Bing.
          </p>
          <router-link to="/service-single" class="my-2 learn-more"
            >Saber más<i class="bi bi-arrow-right"></i
          ></router-link>
        </div>
      </div>
      <div
        class="col-md-6 col-lg-4 mx-auto"
        data-aos="fade-right"
        data-aos-delay="350"
      >
        <!-- service number 4 -->
        <div class="box box-service box-hover">
          <div class="box-icon my-2">
            <i class="bi bi-code-square"></i>
          </div>
          <h2 class="title-2 my-2">Integraciones APIs</h2>
          <p>
            Hoy existen muchos servicios que ofrecen soluciones a las empresas
            que funcionan como islas. Brindamos servicios de integración de
            software y APIs de servicios de terceros en una única interfaz de
            usuarios.
          </p>
          <router-link to="/service-single" class="my-2 learn-more"
            >Saber más <i class="bi bi-arrow-right"></i
          ></router-link>
        </div>
      </div>
      <div
        class="col-md-6 col-lg-4 mx-auto"
        data-aos="fade-up"
        data-aos-delay="300"
      >
        <!-- service number 5 -->
        <div class="box box-service box-hover">
          <div class="box-icon my-2">
            <i class="bi bi-bug-fill"></i>
          </div>
          <h2 class="title-2 my-2">Aplicaciones Seguras</h2>
          <p>
            Nos preocupamos porque cada aplicación que desarrollamos cuente con
            las mejores práctica de seguridad que tenemos en el mercado.
          </p>
          <router-link to="/service-single" class="my-2 learn-more"
            >Saber más <i class="bi bi-arrow-right"></i
          ></router-link>
        </div>
      </div>
      <div class="col-md-6 col-lg-4 mx-auto">
        <!-- service number 6 -->
        <div
          class="box box-service box-hover"
          data-aos="fade-left"
          data-aos-delay="350"
        >
          <div class="box-icon my-2">
            <i class="bi bi-android2"></i>
          </div>
          <h2 class="title-2 my-2">Desarrollo Apps</h2>
          <p>
            Desarrollo aplicaciones híbridas para ejecutarla desde su equipo
            móviles con una gran experiencia de usuarios.
          </p>
          <router-link to="/service-single" class="my-2 learn-more"
            >Saber más <i class="bi bi-arrow-right"></i
          ></router-link>
        </div>
      </div>
    </div>
  </section>
  <!-- ============== End services section ========== -->
</template>