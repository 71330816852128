<template>
  <div>
    <!--Hero Section-->
    <hero-section></hero-section>
    <!--Services Section-->
    <services-section></services-section>
    <!--About Section-->
    <about-section></about-section>
    <!--Numbers Section-->
    <numbers-section></numbers-section>
    <!--Why Us Section-->
    <with-us-section></with-us-section>
    <!--PortFolio Section-->
    <portfolio-section></portfolio-section>
    <!--Cliens Section-->
    <clients-section></clients-section>
    <!--Team Section-->
    <team-section></team-section>
    <!--Blog Section-->
    <blog-section></blog-section>
    <!--FAQ Section-->
    <faq-section></faq-section>
    <!--Contact Section-->
    <contact-section></contact-section>
  </div>
</template>
<script setup>
</script>