<template>
  <!-- =========== start 404 page ============= -->
  <div id="particles-js"></div>
  <div class="fzf">
    <div class="content">
      <img src="images/404/404.png" alt="" />
      <h1>Página no encontrada</h1>
      <p>Lo sentimos, no hemos podido encontrar la página que estás buscando.</p>
      <router-link to="/" class="btn">Ir al  inicio</router-link>
    </div>
  </div>
  <!-- =========== end 404 page ============= -->
</template>