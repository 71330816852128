import ApiService from "./apiService";

// Functions and Methods.
const ApiAdminService = {
   GetContent: function(relativeUrl) {
      let url = ApiService.GetApiMainBaseUrl();
      url = url.substring(0, url.length - 1);
      url = url + relativeUrl;      
      return url;
   },
   GetTechnologies: async function () {
      const httpClientMain = ApiService.GetHttpMainClient();
      return httpClientMain.get("api/technologies?populate=*");
   },
   GetCustomers: async function () {
      const httpClientMain = ApiService.GetHttpMainClient();
      return httpClientMain.get("api/customers");
   },
}

export default ApiAdminService;
export { ApiAdminService };