<template>
  <!-- ============== start team section ========== -->
  <div class="container team">
    <div class="heading">
      <h4 class="pretitle" data-aos="fade-up">Equipo</h4>
      <h1 class="title col-12" data-aos="fade-up" data-aos-delay="100">
        Nuestros Equipo
      </h1>
      <p class="col-lg-7 col-12" data-aos="fade-up" data-aos-delay="150">
        Somos un equipo creado para manejar tus proyectos desde lo más simple hasta lo más complejo.
      </p>
    </div>
    <div class="row gy-4 gx-4">
      <div
        class="col-lg-3 col-md-6 col-12 mx-auto"
        data-aos="fade-right"
        data-aos-delay="200"
      >
        <div class="box">
          <div class="team-img">
            <img src="images/team/team-1.png" alt="team-img" />
          </div>
          <h3 class="my-2 title-2">Ely Michael Núñez</h3>
          <h6>Project Manager and Team Lead</h6>
          <div class="social-icons d-flex">
            <a href="https://www.facebook.com/sitcsrd/" target="_blank"><i class="bi bi-facebook"></i></a>
            <a href="https://www.instagram.com/sitcsrd/" target="_blank"><i class="bi bi-instagram"></i></a>
            <a href="http://www.twitter.com/sitcsrd/" target="_blank"><i class="bi bi-twitter-x"></i></a>
            <a href="https://www.linkedin.com/company/sitcsrd/" target="_blank"><i class="bi bi-linkedin"></i></a>
          </div>
        </div>
      </div>
      <div
        class="col-lg-3 col-md-6 col-12 mx-auto"
        data-aos="fade-up"
        data-aos-delay="250"
      >
        <div class="box">
          <div class="team-img">
            <img src="images/team/team-2.jpg" alt="team-img" />
          </div>
          <h3 class="my-2 title-2">Miledys Taveras</h3>
          <h6>Product Owner</h6>
          <div class="social-icons d-flex">
            <a href="https://www.facebook.com/sitcsrd/" target="_blank"><i class="bi bi-facebook"></i></a>
            <a href="https://www.instagram.com/sitcsrd/" target="_blank"><i class="bi bi-instagram"></i></a>
            <a href="http://www.twitter.com/sitcsrd/" target="_blank"><i class="bi bi-twitter-x"></i></a>
            <a href="https://www.linkedin.com/company/sitcsrd/" target="_blank"><i class="bi bi-linkedin"></i></a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- ============== end team section ========== -->
</template>