<template>
  <!-- ======= srart Breadcrumbs ======= -->
  <section class="breadcrumbs py-4" data-aos="fade-up" data-aos-delay="100">
    <div class="container">
      <ol>
        <li><router-link to="/">Inicio</router-link></li>
        <li>Condiciones de uso</li>
      </ol>
      <h2 class="title">Condiciones de uso</h2>
      <p class="col-lg-10 col-12">
        ..
      </p>
    </div>
  </section>
  <!-- End Breadcrumbs -->
  <div id="particles-js"></div>
  <!--Terms of use-->
   <term-of-use-section></term-of-use-section>
</template>