<template>
  <!-- ============== Start portfolio section ========== -->

  <section class="container portfolio py-5" id="portfolio">
    <div class="heading">
      <h4 class="pretitle" data-aos="fade-up">Librerías y Componentes</h4>
      <h1 class="title col-12" data-aos="fade-up" data-aos-delay="100">
        Nuestras Librerías
      </h1>
    </div>

    <div v-if="state.records.length > 0" class="row gy-4 gx-4">
      <div
        v-for="(item, index) in state.records"
        :key="index"
        class="col-lg-4 col-md-6 col-12 mx-auto"
        data-aos="fade-left"
        data-aos-delay="250"
      >
        <div class="box card border-0">
          <h1 class="title-2">
            {{ item.title }}
          </h1>
          <div class="col-12">
            <img
              v-if="item.image"
              :src="item.image"
              alt="blog-img"
              class="img-fluid rounded"
            />
            <svg
              v-else
              class="bd-placeholder-img bd-placeholder-img-lg img-fluid w-100"
              width="100%"
              height="100%"
              xmlns="http://www.w3.org/2000/svg"
              role="img"
              preserveAspectRatio="xMidYMid slice"
              focusable="false"
            >
              <title>Placeholder</title>
              <rect width="100%" height="100%" fill="#868e96"></rect>
              <text x="50%" y="50%" fill="#dee2e6" dy=".3em">
                Imagen No Disponible
              </text>
            </svg>
          </div>
          <div class="card-body d-flex flex-column">
            <div class="info d-flex justify-content-between">
              <h4>
                <i class="bi bi-person-fill title-2"></i
                >{{ item.publisher }}
              </h4>
            </div>
            <p>
              {{ item.content }}
            </p>
            <a
              class="learn-more mt-auto"
              :href="item.url"
              target="_blank"
            >
              Fuente
            </a>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="my-5 box box-service py-4 text-center">
      <h1 class="title box py-4">404</h1>
      <h2 class="subtitle py-4">Contenido no encontrado</h2>
    </div>
  </section>
  <!-- ============== End portfolio section ========== -->
</template>
<script setup>
import { reactive, onBeforeMount } from "vue";

const state = reactive({
  records: [],
});

onBeforeMount(() => {
  state.records = [
    {
      id: 1,
      title: "Odontograma Realizado en Vue 2",
      content:
        "Ejemplo de como dibujar un odontograma using Javascript y un archivo configuración en formato json. Pendiente habilitar preparar la librería para NPM.",
      publisher: "Ely Michael Núñez",
      image:
        "https://github.com/elymichael/odontogramvue/raw/master/public/img/Demo.png",
      url: "https://github.com/elymichael/odontogramvue",
    },
    {
      id: 2,
      title: "Roadmap para convertirte en FullStack",
      content:
        "Este roadmap te brinda la oportunidad de convertirte en full stack developer siguiente los lineamientos de un programador con más de 2 decadas activo.",
      publisher: "Ely Michael Núñez",
      url: "https://github.com/elymichael/Full-Stack-Road-Roadmap-For-Old-Developer",
    },
    {
      id: 3,
      title: "Componente de cumpleaños para SharePoint",
      content:
        "El Web Part de cumpleaños muestra los próximos cumpleaños a celebrarse en los días próximos en una compañía mediante la lectura de la base de datos de cumpleaños cargados a sharepoint a una lista en el tenant's root de sharepoint online con el nombre de 'Birthdays'.",
      publisher: "Ely Michael Núñez",
      image:
        "https://github.com/elymichael/spfx-birthdays-so-webpart/raw/master/assets/birthdays.png",
      url: "https://github.com/elymichael/spfx-birthdays-so-webpart",
    },
  ];
  //state.records = [];
});
</script>