<template>
  <!-- ============== Start contact section ========== -->
  <section class="container contact py-5" id="contact">
    <div class="heading">
      <h4 class="pretitle" data-aos="fade-up">Contactos</h4>
      <h1 class="title col-12" data-aos="fade-up" data-aos-delay="100">
        Contactenos para cualquier duda o pregunta
      </h1>
      <p class="col-lg-7 col-12" data-aos="fade-up" data-aos-delay="150">
        Puede contactarnos directamente para cualquier duda o pregunta que
        tengan sobre nuestros servicios o algún interés en nuestros servicios.
      </p>
    </div>
    <div class="row gx-4">
      <div class="col-12 col-lg-6 gy-3">
        <h2 class="title-2" data-aos="fade-right" data-aos-delay="200">
          información de contacto :
        </h2>
        <div
          class="info d-flex my-4"
          data-aos="fade-right"
          data-aos-delay="250"
        >
          <h5><i class="bi bi-envelope-fill mx-4"></i>info@sitcsrd.com</h5>
        </div>
        <div class="info d-flex my-4" data-aos="fade-up" data-aos-delay="300">
          <h5><i class="bi bi-phone-fill mx-4"></i>(809) 540-0303</h5>
        </div>
        <div class="info d-flex my-4" data-aos="fade-up" data-aos-delay="350">
          <h5>
            <i class="bi bi-geo-alt-fill mx-4"></i>Calle Macao #9, Ethan 8,
            Arroyo Hondo Viejo
          </h5>
        </div>
        <div class="info d-flex my-4" data-aos="fade-up" data-aos-delay="400">
          <h5><i class="bi bi-instagram mx-4"></i>SitcsRD</h5>
        </div>
      </div>
      <div class="col-12 col-lg-6">
        <!--Form To have user messages-->
        <form class="main-form" id="contact-us-form">
          <div class="col-12">
            <div class="row g-3 mb-1">
              <div
                class="col-lg-6 col-12"
                data-aos="fade-right"
                data-aos-delay="200"
              >
                <input
                  placeholder="--Nombre--"
                  type="text"
                  id="name"
                  name="name"
                  class="text-input"
                  v-model="state.form.name"
                />
                <span
                  v-if="v$.form.name.$error && v$.form.name.required"
                  class="small text-danger"
                  >Introduzca su nombre.</span
                >
              </div>
              <div
                class="col-lg-6 col-12"
                data-aos="fade-left"
                data-aos-delay="200"
              >
                <input
                  placeholder="--Asunto--"
                  type="text"
                  id="subject"
                  name="subject"
                  class="text-input"
                  v-model="state.form.title"
                />
                <span
                  v-if="v$.form.title.$error && v$.form.title.required"
                  class="small text-danger"
                  >Introduzca el asunto.</span
                >
              </div>
            </div>
          </div>
          <div class="col-12" data-aos="fade-up" data-aos-delay="250">
            <input
              placeholder="--Correo Electrónico--"
              type="email"
              id="email"
              name="email"
              class="text-input my-2"
              v-model="state.form.emailTo"
            />
            <span
              v-if="v$.form.emailTo.$error && v$.form.emailTo.required"
              class="small text-danger"
              >Introduzca su dirección de correo.</span
            >
          </div>
          <div class="col-12" data-aos="fade-up" data-aos-delay="300">
            <textarea
              placeholder="--Mensaje--"
              class="text-input my-2"
              rows="7"
              cols="30"
              id="message"
              name="message"
              v-model="state.form.message"
            ></textarea>
            <span
              v-if="v$.form.message.$error && v$.form.message.required"
              class="small text-danger"
              >Introduzca el mensaje.</span
            >
          </div>
          <div class="col-12" data-aos="fade-up" data-aos-delay="350">
            <button type="button" class="btn" @click="SendEmail">Enviar</button>
          </div>
        </form>
      </div>
    </div>
    <!-- ========== Maps Location ========== -->
    <iframe
      class="contact-map"
      data-aos="fade-up"
      data-aos-delay="450"
      src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15135.114434682151!2d-69.9578330802246!3d18.4936848!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8eaf898d31d180d1%3A0x82f21ccf5acd89b3!2zMTjCsDI5JzE3LjIiTiA2OcKwNTYnNDMuMiJX!5e0!3m2!1ses!2sdo!4v1534942018404"
      fullscreen=""
    ></iframe>
  </section>
  <!-- ============== end contact section ========== -->
</template>
<script setup>
import { reactive } from "vue";
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { Email } from "@/helpers/smtp.js";
import { GetErrorMessage } from "@/helpers/helper";

const state = reactive({
  message: "",
  form: {
    name: "",
    title: "",
    message: "",
    emailTo: "",
  },
});

const rules = {
  form: {
    name: { required },
    title: { required },
    message: { required },
    emailTo: { required },
  },
};

const v$ = useVuelidate(rules, state);

const SendEmail = async () => {
  state.message = "";
  try {
    v$.value.form.$touch();
    if (!v$.value.form.$invalid) {
      Email.send({
        Host: "email-smtp.us-east-1.amazonaws.com",
        Username: "AKIAXBPZJILHC4U7SCFF",
        Password: "BNxsBKJdHOjangrFAl/EVIJX/MA666xf43yRhJI3ZNg3",
        To: "elymichael@sitcsrd.com",
        From: "elymichael@sitcsrd.com",
        Subject: state.form.title,
        Body: JSON.stringify({
          from: state.form.emailTo,
          name: state.form.name,
          message: state.form.message,
        }),
      }).then((message) => alert(message));
      console.log(state.form);

      v$.value.form.$reset();
    }
  } catch (error) {
    state.message = GetErrorMessage(error);
  }
};
</script>