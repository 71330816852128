// Layout Components
import PreLoader from "@/components/PreLoader.vue";
import FooterSection from "@/components/FooterSection.vue";
import HeaderSection from "@/components/HeaderSection.vue";
// Views Components
import HeroSection from "@/components/HeroSection.vue";
import ServicesSection from "@/components/ServicesSection.vue";
import AboutSection from "@/components/AboutSection.vue";
import NumbersSection from "@/components/NumbersSection.vue";
import WithUsSection from "@/components/WithUsSection.vue";
import PortfolioSection from "@/components/PortfolioSection.vue";
import ClientsSection from "@/components/ClientsSection.vue";
import TeamSection from "@/components/TeamSection.vue";
import BlogSection from "@/components/BlogSection.vue";
import FaqSection from "@/components/FAQSection.vue";
import ContactSection from "@/components/ContactSection.vue";
import ComponentsSection from "@/components/ComponentsSection.vue";
import TemplatesSection from "@/components/TemplatesSection.vue";
import ServiceSingleSection from "@/components/ServiceSingleSection.vue";
import PortfolioSingleSection from "@/components/PortfolioSingleSection.vue";
import BlogSingleSection from "@/components/BlogSingleSection.vue"
import TermOfUseSection from "./TermOfUseSection.vue";
import PrivacyPolicySection from "./PrivacyPolicySection.vue";
import TechnologySection from "@/components/TechnologySection.vue";

export default (app) => {
    // Layouts Components
    app.component("PreLoader", PreLoader);
    app.component("FooterSection", FooterSection);
    app.component("HeaderSection", HeaderSection);
    // Views Components
    app.component("HeroSection", HeroSection);
    app.component("ServicesSection", ServicesSection);
    app.component("ServiceSingleSection", ServiceSingleSection);
    app.component("AboutSection", AboutSection);
    app.component("NumbersSection", NumbersSection);
    app.component("WithUsSection", WithUsSection);
    app.component("PortfolioSection", PortfolioSection);
    app.component("PortfolioSingleSection", PortfolioSingleSection);
    app.component("ClientsSection", ClientsSection);
    app.component("TeamSection", TeamSection);
    app.component("BlogSection", BlogSection);
    app.component("BlogSingleSection", BlogSingleSection);
    app.component("FaqSection", FaqSection);
    app.component("ContactSection", ContactSection);
    app.component("ComponentsSection", ComponentsSection);
    app.component("TemplatesSection", TemplatesSection);
    app.component("TermOfUseSection", TermOfUseSection);
    app.component("PrivacyPolicySection", PrivacyPolicySection);
    app.component("TechnologySection", TechnologySection);
};