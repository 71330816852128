<template>
  <!-- ============== Start Hero section ========== -->
  <section
    class="hero d-flex flex-column justify-content-center align-items-center"
    id="hero"
  >
    <div id="particles-js"></div>
    <div class="container">
      <div class="row justify-content-center align-items-center">
        <div class="hero-text col-12 col-lg-5">
          <div class="row">
            <div
              class="hero-icons d-flex gap-lg-3 gap-2 my-lg-3 my-2"
              data-aos="fade-up"
              data-aos-delay="100"
            >
              <a href="https://www.facebook.com/sitcsrd/" target="_blank"><i class="bi bi-facebook"></i></a>
              <a href="https://www.instagram.com/sitcsrd/" target="_blank"><i class="bi bi-instagram"></i></a>
              <a href="http://www.twitter.com/sitcsrd/" target="_blank"><i class="bi bi-twitter-x"></i></a>
              <a href="https://www.linkedin.com/company/sitcsrd/" target="_blank"><i class="bi bi-linkedin"></i></a>
            </div>
            <div class="col-12">
              <h1 class="title col-12" data-aos="fade-up" data-aos-delay="150">
                Smart IT
                <span class="unique-text">Consulting Solutions</span>.
              </h1>
            </div>
            <div
              class="col-lg-10 col-12"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <p>
                Sitcs provee servicios de desarrollo de software a la medida,
                integraciones de APIs, consultoría y asesoría de sistemas y base
                de datos.
              </p>
            </div>
            <div class="col-10" data-aos="fade-up" data-aos-delay="250">
              <a href="/contact" class="btn">Escribenos</a>
            </div>
          </div>
        </div>
        <div
          class="col-12 col-lg-7 mx-md-auto text-center"
          data-aos="fade-left"
          data-aos-delay="100"
        >
          <div class="hero-image">
            <div class="hero-img" data-tilt>
              <img
                class="img-fluid"
                alt="hero-img"
                src="images/hero/hero_main_blue.png"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- ============== End Hero section ========== -->
</template>
<script setup>
</script>
