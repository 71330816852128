export function GetErrorMessage(error) {
    var message = "";

    if (error.response?.data?.ErrorMessage) {
        message == "" ? 
            message = `Error: ${error.response.data.ErrorMessage}` : 
            message += ", " + error.response.data.ErrorMessage;
    }
    
    if (error.message) {
        message == "" ? 
            message = `Error: ${error.message}` : 
            message += ", " + error.message;
    }

    if (!error.response?.data?.errors) {
        if (typeof error.response?.data !== "object") {
            if (message.length > 0) {
                message += ", " + error.response?.data;
            } else {
                message += error.response?.data;
            }
        } else {
            if (error.response?.data?.message) {
                message += ", " + error.response.data.message;
            }
        }
    }

    if (error.response?.data?.errors) {        
        for (const item of Object.keys(error.response.data.errors)) {            
            message += ", " + error.response.data.errors[item][0];
        }
    }

    return message;
}

export function GetFormatDate(value) {
    if (value != undefined || value != null) {
        const date = new Date(value);
        return Intl.DateTimeFormat('default', { dateStyle: "long", timeStyle: "medium" }).format(date);
    }
}

export function GetFormatOnlyDate(value) {
    if (value != undefined || value != null) {
        const date = new Date(value);
        return Intl.DateTimeFormat('default', { dateStyle: "long" }).format(date);
    }
}

export function GetFormatCurrency(value) {
    if(value == null || value == undefined) { value = 0; }
    return Number(value).toLocaleString('us-US', { style: 'currency', currency: 'USD' });
}

export function GetFormatCurrencyRD(value) {
    if(value == null || value == undefined) { value = 0; }
    return Number(value).toLocaleString('es-US', { style: 'currency', currency: 'DOP' });
}

export function GetFormatNumber(value) {
    if(value == null || value == undefined) { value = 0; }
    return Number(value).toLocaleString('es-US');
}

export function GetAppDate() {
    return new Date(new Date().getTime() - new Date().getTimezoneOffset() * 60 * 1000)
    .toISOString()
    .substr(0, 10);
}

function addStyles(win, styles) {
    styles.forEach((style) => {
        let link = win.document.createElement("link");
        link.setAttribute("rel", "stylesheet");
        link.setAttribute("type", "text/css");
        link.setAttribute("href", style);
        win.document.getElementsByTagName("head")[0].appendChild(link);
    });
}

export function PrintElement(el, options = {}, cb = () => true) {
    let
        defaultStyles = [];
    let {
        styles = defaultStyles
    } = options;

    const element = window.document.getElementById(el);

    if (!element) {
        alert(`Element to print #${el} not found!`);
        return;
    }

    var ifprint = document.createElement("iframe");
    document.body.appendChild(ifprint);
    ifprint.setAttribute("style", "height:0;width:0;");

    const win = ifprint.contentWindow;

    win.document.write(`
          <html>
            <head>
              <title>${window.document.title}</title>
            </head>
            <body>
              ${element.innerHTML}
            </body>
          </html>
        `);

    addStyles(win, styles);

    setTimeout(() => {
        win.document.close();
        win.focus();
        win.print();
        win.close();
        document.body.removeChild(ifprint);
        cb();
    }, 1);

    return true;
}
