<template>
    <!-- ======= srart Breadcrumbs ======= -->
  <section class="breadcrumbs py-4" data-aos="fade-up" data-aos-delay="100">
    <div class="container">
      <ol>
        <li><router-link to="/">Inicio</router-link></li>
        <li>Components</li>
      </ol>
      <h2 class="title">Librerías y Componentes</h2>
      <p class="col-lg-10 col-12">Ponemos a tu disposición algunos enlaces a librerías y componentes que te asistiran en tus labores diarias.</p>
    </div>
  </section>
  <div id="particles-js"></div>
  <!-- Libraries and Components-->
  <components-section></components-section>
  <!-- FAQ Seection -->
  <faq-section></faq-section>
</template>