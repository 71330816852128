<template>
  <!-- ======= srart Breadcrumbs ======= -->
  <section class="breadcrumbs py-4" data-aos="fade-up" data-aos-delay="100">
    <div class="container">
      <ol>
        <li><router-link to="/">Inicio</router-link></li>
        <li>Blog</li>
      </ol>
      <h2 class="title">Nuestro Blog</h2>
      <p class="col-lg-10 col-12">
        En este blog vamos a estar publicando algunos artículos sobre noticias
        tecnologícas, desmitificar algunos mitos sobre el desarrollo de software
        a la medida, buenas prácticas de noticias.
      </p>
    </div>
  </section>
  <!-- End Breadcrumbs -->
  <div id="particles-js"></div>
  <!-- Blog Single Section -->
  <blog-single-section></blog-single-section>
  <!-- FAQ Seection -->
  <faq-section></faq-section>
</template>