<template>
  <!-- ======= srart Breadcrumbs ======= -->
  <section class="breadcrumbs py-4" data-aos="fade-up" data-aos-delay="100">
    <div class="container">
      <ol>
        <li><router-link to="/">Inicio</router-link></li>
        <li>Nosotros</li>
      </ol>
      <h2 class="title">Sobre Nosotros</h2>
      <p class="col-lg-10 col-12">.</p>
    </div>
  </section>
  <!-- End Breadcrumbs -->
  <div id="particles-js"></div>
  <!-- About -->
  <about-section></about-section>
  <!--With Us Section-->
  <with-us-section></with-us-section>
  <!--Technologies-->
  <technology-section></technology-section>
  <!-- FAQ Seection -->
  <faq-section></faq-section>
</template>