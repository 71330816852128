<template>
  <!-- ======= Blog Single Section ======= -->
  <section id="blog" class="blog mt-5">
    <div class="container">
      <div class="row">
        <div
          class="col-lg-8 entries"
          data-aos="fade-right"
          data-aos-delay="150"
        >
          <article class="entry entry-single">
            <div class="entry-img">
              <img
                src="images/blog/post-images/1.jpg"
                alt="post-image"
                class="img-fluid"
              />
            </div>
            <h2 class="entry-title">
              <router-link to="/blog-single"
                >Dolorum optio tempore voluptas dignissimos cumque fuga qui
                quibusdam quia</router-link
              >
            </h2>
            <div class="entry-meta">
              <ul>
                <li class="d-flex align-items-center">
                  <i class="bi bi-person"></i>
                  <router-link to="/blog-single">John Doe</router-link>
                </li>
                <li class="d-flex align-items-center">
                  <i class="bi bi-clock"></i>
                  <router-link to="/blog-single"
                    ><time datetime="2024-01-01">Jan 1, 2024</time></router-link
                  >
                </li>
                <li class="d-flex align-items-center">
                  <i class="bi bi-chat-dots"></i>
                  <router-link to="/blog-single">12 Comments</router-link>
                </li>
              </ul>
            </div>
            <div class="entry-content">
              <p>
                Similique neque nam consequuntur ad non maxime aliquam quas.
                Quibusdam animi praesentium. Aliquam et laboriosam eius aut
                nostrum quidem aliquid dicta. Et eveniet enim. Qui velit est ea
                dolorem doloremque deleniti aperiam unde soluta. Est cum et quod
                quos aut ut et sit sunt. Voluptate porro consequatur assumenda
                perferendis dolore.
              </p>
              <p>
                Sit repellat hic cupiditate hic ut nemo. Quis nihil sunt non
                reiciendis. Sequi in accusamus harum vel aspernatur. Excepturi
                numquam nihil cumque odio. Et voluptate cupiditate.
              </p>

              <p>
                Sed quo laboriosam qui architecto. Occaecati repellendus omnis
                dicta inventore tempore provident voluptas mollitia aliquid. Id
                repellendus quia. Asperiores nihil magni dicta est suscipit
                perspiciatis. Voluptate ex rerum assumenda dolores nihil
                quaerat. Dolor porro tempora et quibusdam voluptas. Beatae aut
                at ad qui tempore corrupti velit quisquam rerum. Omnis dolorum
                exercitationem harum qui qui blanditiis neque. Iusto autem
                itaque. Repudiandae hic quae aspernatur ea neque qui. Architecto
                voluptatem magni. Vel magnam quod et tempora deleniti error
                rerum nihil tempora.
              </p>
              <h3>Et quae iure vel ut odit alias.</h3>
              <p>
                Officiis animi maxime nulla quo et harum eum quis a. Sit hic in
                qui quos fugit ut rerum atque. Optio provident dolores atque
                voluptatem rem excepturi molestiae qui. Voluptatem laborum omnis
                ullam quibusdam perspiciatis nulla nostrum. Voluptatum est
                libero eum nesciunt aliquid qui. Quia et suscipit non sequi.
                Maxime sed odit. Beatae nesciunt nesciunt accusamus quia aut
                ratione aspernatur dolor. Sint harum eveniet dicta
                exercitationem minima. Exercitationem omnis asperiores natus
                aperiam dolor consequatur id ex sed. Quibusdam rerum dolores
                sint consequatur quidem ea. Beatae minima sunt libero soluta
                sapiente in rem assumenda. Et qui odit voluptatem. Cum quibusdam
                voluptatem voluptatem accusamus mollitia aut atque aut.
              </p>
              <img
                src="images/blog/post-images/2.jpg"
                class="img-fluid"
                alt="post-image"
              />
              <h3>Ut repellat blanditiis est dolore sunt dolorum quae.</h3>
              <p>
                Rerum ea est assumenda pariatur quasi et quam. Facilis nam porro
                amet nostrum. In assumenda quia quae a id praesentium. Quos
                deleniti libero sed occaecati aut porro autem. Consectetur sed
                excepturi sint non placeat quia repellat incidunt labore. Autem
                facilis hic dolorum dolores vel. Consectetur quasi id et optio
                praesentium aut asperiores eaque aut. Explicabo omnis quibusdam
                esse. Ex libero illum iusto totam et ut aut blanditiis.
                Veritatis numquam ut illum ut a quam vitae.
              </p>
              <p>
                Alias quia non aliquid. Eos et ea velit. Voluptatem maxime enim
                omnis ipsa voluptas incidunt. Nulla sit eaque mollitia nisi
                asperiores est veniam.
              </p>
            </div>
            <div class="entry-footer">
              <i class="bi bi-folder"></i>
              <ul class="cats">
                <li><a href="#">Business</a></li>
              </ul>
              <i class="bi bi-tags"></i>
              <ul class="tags">
                <li><a href="#">Creative</a></li>
                <li><a href="#">Tips</a></li>
                <li><a href="#">Marketing</a></li>
              </ul>
            </div>
          </article>
          <!-- End blog entry -->

          <div class="blog-author d-flex align-items-center">
            <img
              src="images/team/team-1.jpg"
              class="rounded-circle float-left"
              alt="author-img"
            />
            <div>
              <h4>Jane Smith</h4>
              <div class="social-links">
                <a href="https://twitter-xs.com/#" target="_blank"
                  ><i class="bi bi-twitter-x"></i
                ></a>
                <a href="https://facebook.com/#" target="_blank"
                  ><i class="bi bi-facebook"></i
                ></a>
                <a href="https://instagram.com/#" target="_blank"
                  ><i class="biu bi-instagram"></i
                ></a>
              </div>
              <p>
                Itaque eum sed possimus accusantium. Quas repellat voluptatem
                officia numquam sint aspernatur voluptas. Esse et accusantium ut
                unde voluptas.
              </p>
            </div>
          </div>
          <!-- End blog author bio -->
          <div class="blog-comments">
            <h4 class="comments-count">8 Comments</h4>
            <div id="comment-1" class="comment">
              <div class="d-flex">
                <div class="comment-img">
                  <img src="images/team/team-2.jpg" alt="comment-img" />
                </div>
                <div>
                  <h5>
                    <a href="#">Georgia Reader</a>
                    <a href="#" class="reply"
                      ><i class="bi bi-reply-fill"></i> Reply</a
                    >
                  </h5>
                  <time datetime="2024-01-01">01 Jan, 2024</time>
                  <p>
                    Et rerum totam nisi. Molestiae vel quam dolorum vel
                    voluptatem et et. Est ad aut sapiente quis molestiae est qui
                    cum soluta. Vero aut rerum vel. Rerum quos laboriosam
                    placeat ex qui. Sint qui facilis et.
                  </p>
                </div>
              </div>
            </div>
            <!-- End comment #1 -->
            <div id="comment-2" class="comment">
              <div class="d-flex">
                <div class="comment-img">
                  <img src="images/team/team-3.jpg" alt="comment-img" />
                </div>
                <div>
                  <h5>
                    <a href="#">Aron Alvarado</a>
                    <a href="#" class="reply"
                      ><i class="bi bi-reply-fill"></i> Reply</a
                    >
                  </h5>
                  <time datetime="2024-01-01">01 Jan, 2024</time>
                  <p>
                    Ipsam tempora sequi voluptatem quis sapiente non. Autem
                    itaque eveniet saepe. Officiis illo ut beatae.
                  </p>
                </div>
              </div>

              <div id="comment-reply-1" class="comment comment-reply">
                <div class="d-flex">
                  <div class="comment-img">
                    <img
                      src="images/testimonials/testimonials-3.jpg"
                      alt="comment-img"
                    />
                  </div>
                  <div>
                    <h5>
                      <a href="#">john smith</a>
                      <a href="#" class="reply"
                        ><i class="bi bi-reply-fill"></i> Reply</a
                      >
                    </h5>
                    <time datetime="2024-01-01">01 Jan, 2024</time>
                    <p>
                      Enim ipsa eum fugiat fuga repellat. Commodi quo quo dicta.
                      Est ullam aspernatur ut vitae quia mollitia id non. Qui ad
                      quas nostrum rerum sed necessitatibus aut est. Eum
                      officiis sed repellat maxime vero nisi natus. Amet
                      nesciunt nesciunt qui illum omnis est et dolor recusandae.
                      Recusandae sit ad aut impedit et. Ipsa labore dolor
                      impedit et natus in porro aut. Magnam qui cum. Illo
                      similique occaecati nihil modi eligendi. Pariatur
                      distinctio labore omnis incidunt et illum. Expedita et
                      dignissimos distinctio laborum minima fugiat. Libero
                      corporis qui. Nam illo odio beatae enim ducimus. Harum
                      reiciendis error dolorum non autem quisquam vero rerum
                      neque.
                    </p>
                  </div>
                </div>

                <div id="comment-reply-2" class="comment comment-reply">
                  <div class="d-flex">
                    <div class="comment-img">
                      <img
                        src="images/testimonials/testimonials-2.jpg"
                        alt="comment-img"
                      />
                    </div>
                    <div>
                      <h5>
                        <a href="#">Sianna Ramsay</a>
                        <a href="#" class="reply"
                          ><i class="bi bi-reply-fill"></i> Reply</a
                        >
                      </h5>
                      <time datetime="2024-01-01">01 Jan, 2024</time>
                      <p>
                        Et dignissimos impedit nulla et quo distinctio ex nemo.
                        Omnis quia dolores cupiditate et. Ut unde qui eligendi
                        sapiente omnis ullam. Placeat porro est commodi est
                        officiis voluptas repellat quisquam possimus.
                        Perferendis id consectetur necessitatibus.
                      </p>
                    </div>
                  </div>
                </div>
                <!-- End comment reply #2-->
              </div>
              <!-- End comment reply #1-->
            </div>
            <!-- End comment #2-->

            <div id="comment-3" class="comment">
              <div class="d-flex">
                <div class="comment-img">
                  <img
                    src="images/testimonials/testimonials-1.jpg"
                    alt="comment-img"
                  />
                </div>
                <div>
                  <h5>
                    <a href="#">Nolan Davidson</a>
                    <a href="#" class="reply"
                      ><i class="bi bi-reply-fill"></i> Reply</a
                    >
                  </h5>
                  <time datetime="2024-01-01">01 Jan, 2024</time>
                  <p>
                    Distinctio nesciunt rerum reprehenderit sed. Iste omnis eius
                    repellendus quia nihil ut accusantium tempore. Nesciunt
                    expedita id dolor exercitationem aspernatur aut quam ut.
                    Voluptatem est accusamus iste at. Non aut et et esse qui sit
                    modi neque. Exercitationem et eos aspernatur. Ea est
                    consequuntur officia beatae ea aut eos soluta. Non qui
                    dolorum voluptatibus et optio veniam. Quam officia sit
                    nostrum dolorem.
                  </p>
                </div>
              </div>
            </div>
            <!-- End comment #3 -->

            <div id="comment-4" class="comment">
              <div class="d-flex">
                <div class="comment-img">
                  <img src="images/team/team-4.jpg" alt="comment-img" />
                </div>
                <div>
                  <h5>
                    <a href="#">ahmad errami</a>
                    <a href="#" class="reply"
                      ><i class="bi bi-reply-fill"></i> Reply</a
                    >
                  </h5>
                  <time datetime="2024-01-01">01 Jan, 2024</time>
                  <p>
                    Dolorem atque aut. Omnis doloremque blanditiis quia eum
                    porro quis ut velit tempore. Cumque sed quia ut maxime. Est
                    ad aut cum. Ut exercitationem non in fugiat.
                  </p>
                </div>
              </div>
            </div>
            <!-- End comment #4 -->

            <div class="reply-form">
              <h4 class="title-2">Leave a Reply</h4>
              <p>
                Your email address will not be published. Required fields are
                marked *
              </p>
              <form action="">
                <div class="row g-3">
                  <div class="col-md-6 form-group">
                    <input
                      name="name"
                      type="text"
                      class="form-control box"
                      placeholder="Your Name*"
                    />
                  </div>
                  <div class="col-md-6 form-group">
                    <input
                      name="email"
                      type="text"
                      class="form-control box"
                      placeholder="Your Email*"
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="col form-group">
                    <input
                      name="website"
                      type="text"
                      class="form-control box"
                      placeholder="Your Website"
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="col form-group">
                    <textarea
                      name="comment"
                      class="form-control box"
                      placeholder="Your Comment*"
                    ></textarea>
                  </div>
                </div>
                <button type="submit" class="btn">Post Comment</button>
              </form>
            </div>
          </div>
          <!-- End blog comments -->
        </div>
        <!-- End blog entries list -->

        <div class="col-lg-4" data-aos="fade-left" data-aos-delay="150">
          <div class="sidebar">
            <h3 class="sidebar-title">Search</h3>
            <div class="sidebar-item search-form">
              <form action="">
                <input type="text" />
                <button type="submit"><i class="bi bi-search"></i></button>
              </form>
            </div>
            <!-- End sidebar search formn-->

            <h3 class="sidebar-title">Categories</h3>
            <div class="sidebar-item categories">
              <ul>
                <li>
                  <a href="#">General <span>(25)</span></a>
                </li>
                <li>
                  <a href="#">Lifestyle <span>(12)</span></a>
                </li>
                <li>
                  <a href="#">Travel <span>(5)</span></a>
                </li>
                <li>
                  <a href="#">Design <span>(22)</span></a>
                </li>
                <li>
                  <a href="#">Creative <span>(8)</span></a>
                </li>
                <li>
                  <a href="#">Educaion <span>(14)</span></a>
                </li>
              </ul>
            </div>
            <!-- End sidebar categories-->

            <h3 class="sidebar-title">Recent Posts</h3>
            <div class="sidebar-item recent-posts">
              <div class="post-item clearfix">
                <img
                  src="images/blog/post-images/1.jpg"
                  alt="recent-post-img"
                />
                <h4>
                  <router-link to="/blog-single"
                    >Nihil blanditiis at in nihil autem</router-link
                  >
                </h4>
                <time datetime="2024-01-01">Jan 23, 2024</time>
              </div>

              <div class="post-item clearfix">
                <img
                  src="images/blog/post-images/2.jpg"
                  alt="recent-post-img"
                />
                <h4><router-link to="/blog-single">Quidem autem et impedit</router-link></h4>
                <time datetime="2024-01-01">Jan 1, 2024</time>
              </div>

              <div class="post-item clearfix">
                <img
                  src="images/blog/post-images/3.jpg"
                  alt="recent-post-img"
                />
                <h4>
                  <router-link to="/blog-single"
                    >Id quia et et ut maxime similique occaecati ut</router-link
                  >
                </h4>
                <time datetime="2024-01-01">Jan 17, 2024</time>
              </div>

              <div class="post-item clearfix">
                <img
                  src="images/blog/post-images/1.jpg"
                  alt="recent-post-img"
                />
                <h4>
                  <router-link to="/blog-single"
                    >Laborum corporis quo dara net para</router-link
                  >
                </h4>
                <time datetime="2024-01-01">Jan 4, 2024</time>
              </div>

              <div class="post-item clearfix">
                <img
                  src="images/blog/post-images/2.jpg"
                  alt="recent-post-img"
                />
                <h4>
                  <router-link to="/blog-single"
                    >Et dolores corrupti quae illo quod dolor</router-link
                  >
                </h4>
                <time datetime="2024-01-01">Jan 24, 2024</time>
              </div>
            </div>
            <!-- End sidebar recent posts-->

            <h3 class="sidebar-title">Tags</h3>
            <div class="sidebar-item tags">
              <ul>
                <li><a href="#">App</a></li>
                <li><a href="#">IT</a></li>
                <li><a href="#">Business</a></li>
                <li><a href="#">Mac</a></li>
                <li><a href="#">Design</a></li>
                <li><a href="#">Office</a></li>
                <li><a href="#">Creative</a></li>
                <li><a href="#">Studio</a></li>
                <li><a href="#">Smart</a></li>
                <li><a href="#">Tips</a></li>
                <li><a href="#">Marketing</a></li>
              </ul>
            </div>
            <!-- End sidebar tags-->
          </div>
          <!-- End sidebar -->
        </div>
        <!-- End blog sidebar -->
      </div>
    </div>
  </section>
  <!-- End Blog Single Section -->
</template>