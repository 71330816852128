<template>
  <!-- ============== Start About section ========== -->
  <section class="about py-2 mt-2" id="about">
    <div class="container">
      <div class="row mt-2 justify-content-center align-items-center">
        <div class="col-12 col-lg-6">
          <h4 class="pretitle" data-aos="fade-up" data-aos-delay="200">
            Sobre nosotros
          </h4>
          <h1 class="title col-12" data-aos="fade-up" data-aos-delay="250">
            Estamos ofreciendo soluciones tecnológica desde
            <span class="unique-text">2018</span>
          </h1>
          <p class="col-lg-10 col-12" data-aos="fade-up" data-aos-delay="300">
            Somos una empresa que nos encontramos a la vanguaria con las últimas
            tecnologías que se encuentran en el mercado, principalmente en
            plataforma de desarrollo sobre la nube como Amazon AWS, Google Cloud
            y Microsoft Azure.
          </p>
          <router-link to="/contact" class="btn" data-aos="fade-up" data-aos-delay="350"
            >Escribenos</router-link
          >
        </div>
        <!-- image -->
        <div
          class="col-12 col-lg-6 d-flex align-items-center"
          data-aos="fade-left"
          data-aos-delay="100"
        >
          <img src="images/about/1.png" alt="about-img" />
        </div>
      </div>
      <div class="row mt-5 py-4 justify-content-center align-items-center">
        <!-- image -->
        <div
          class="col-12 col-lg-6 d-flex align-items-center"
          data-aos="fade-right"
          data-aos-delay="100"
        >
          <img src="images/about/2.png" alt="about-img" />
        </div>
        <div class="col-12 col-lg-6">
          <h1 class="title col-12" data-aos="fade-up" data-aos-delay="200">
            Brindamos soportes a los productos que
            <span class="unique-text">desarrollamos</span> de forma confiable.
          </h1>
          <p class="col-lg-10 col-12" data-aos="fade-up" data-aos-delay="250">
            Un punto donde nos distinguimos es el seguimiendo que le damos a
            nuestros proyectos, con una fecha base de 90 días de seguimiento a
            nuevos desarrollos.
          </p>
          <div class="row gx-2 gy-2 col-12">
            <div
              class="col-lg-6 col-md-6 col-12"
              data-aos="fade-up"
              data-aos-delay="300"
            >
              <div class="box box-hover">
                <i class="bi bi-cloud-fill"></i>
                <h5 class="mx-4 title-2 fw-bold">Servicios en la Nube</h5>
              </div>
            </div>
            <div
              class="col-lg-6 col-md-6 col-12"
              data-aos="fade-up"
              data-aos-delay="350"
            >
              <div class="box box-hover">
                <i class="bi bi-headset"></i>
                <h5 class="mx-4 title-2 fw-bold">Soporte</h5>
              </div>
            </div>
            <div
              class="col-lg-6 col-md-6 col-12"
              data-aos="fade-up"
              data-aos-delay="400"
            >
              <div class="box box-hover">
                <i class="bi bi-file-earmark-code-fill"></i>
                <h5 class="mx-4 title-2 fw-bold">Código seguro</h5>
              </div>
            </div>
            <div
              class="col-lg-6 col-md-6 col-12"
              data-aos="fade-up"
              data-aos-delay="450"
            >
              <div class="box box-hover">
                <i class="bi bi-gear-fill"></i>
                <h5 class="mx-4 title-2 fw-bold">Servicios Automatizados</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-5 py-4 justify-content-center align-items-center">
        <div class="col-12 col-lg-6">
          <h1 class="title col-12" data-aos="fade-up" data-aos-delay="200">
            Contamos con más de 20 años de
            <span class="unique-text">Experiencia</span> en el sector de
            tecnología.
          </h1>
          <p class="col-lg-10 col-12" data-aos="fade-up" data-aos-delay="250">
            En estos 20 años de experiencia hemos tenido la oportunidad de
            trabajar en los principales ámbitos que envuelve el desarrollo de
            software.
          </p>
          <ul class="col-12 bars">
            <li class="py-3" data-aos="fade-up" data-aos-delay="300">
              <div class="justify-content-between d-flex">
                <h5>Integración de APIs</h5>
                <h6>70%</h6>
              </div>
              <div class="progress-bar">
                <div class="progress-bar-fill progress-bar-fill-1"></div>
              </div>
            </li>
            <li class="py-3" data-aos="fade-up" data-aos-delay="350">
              <div class="justify-content-between d-flex">
                <h5>Diseño UI/UX</h5>
                <h6>20%</h6>
              </div>
              <div class="progress-bar">
                <div class="progress-bar-fill progress-bar-fill-2"></div>
              </div>
            </li>
            <li class="py-3" data-aos="fade-up" data-aos-delay="400">
              <div class="justify-content-between d-flex">
                <h5>Desarrollo de Software</h5>
                <h6>55%</h6>
              </div>
              <div class="progress-bar">
                <div class="progress-bar-fill progress-bar-fill-3"></div>
              </div>
            </li>
          </ul>
        </div>
        <!-- image -->
        <div
          class="col-12 col-lg-6 d-flex align-items-center"
          data-aos="fade-left"
          data-aos-delay="100"
        >
          <img src="images/about/3.png" alt="about-img" />
        </div>
      </div>
    </div>
  </section>
  <!-- ============== End About section ========== -->
</template>